import React from "react";
import CommentDetail from "./CommentDetail";

import "../../styles/comments.css";
import PropTypes from 'prop-types';

function Comments({comments}) {

  const commentArray = typeof comments === 'string' ? JSON.parse(comments) : comments;
    // Check if the comments array is not empty
    if (comments.length === 0) {
        return (
          <div className="container">
            <h3>No comments available.</h3>
          </div>
        );
      }
  return (
      <div className="comments-container" style={{ maxHeight: '300px', overflowY: 'auto', position: 'relative' }}>
        <div style={{
          position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1, padding: '10px 0'
        }}>
          <h3 className="comment-text">Comments : {commentArray.length}</h3>
        </div>
        <div className="cotainer">

          {commentArray.map((comment,index) => (

            // Check if both heading and comment are not empty strings before rendering
            comment.heading !== '' && comment.comment !== '' ? (
              <div key={index}>
                <CommentDetail heading={comment.heading} comment={comment.comment} />
                <hr />
              </div>
            ) : null

          ))}
        </div>
      </div>
  );
}

export default Comments;

Comments.propTypes = {
  comments: PropTypes.string.isRequired,
};