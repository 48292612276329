import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Upgrade1 from "../../assets/Upgrade1.png";
import "./index.css";

const PlanUpgradeModel = ({ show, handleClose, premiumType }) => {

  const getPlanName = () => {
    switch (premiumType) {
      case 1:
        return "CB Pro";
      case 2:
        return "CB Plus";
      case 3:
        return "CB Elite";
      case 4:
        return "CB E+E";
      default:
        return "CB Pro";
    }
  };


  if (!show) return null;

  return (
    <Dialog open={show} onClose={handleClose} fullWidth maxWidth="sm" >
      <Box position="relative" sx={{ p: 2 }}>
        <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8, '&:hover': { backgroundColor: 'none', color: "red" } }}>
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          {premiumType ? (
            <Typography variant="h5" align="center" sx={{ color: "#FF4747", fontFamily: 'inter', fontWeight: 'semibold' }}>
              Unlock Insights View!
            </Typography>
          ) : (
            <Typography variant="h5" align="center" sx={{ color: "#FF4747", fontFamily: 'inter', fontWeight: 'semibold' }}>
            Unlock Nationwide Rating!
          </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center" sx={{ p: 1 }}>
            <img className="PlanUpgradeImg" src={Upgrade1} alt="Plan Upgrade" />
            <Typography gutterBottom sx={{ mt: 2, fontWeight: "bold", fontSize: "30px", fontFamily: "Tiro Telugu", background: "linear-gradient(90deg, #8A3F93 0%, #0057ED 50%, #A642A6 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", }}>
              Upgrade Your Plan
            </Typography>
            <Typography variant="body1" className="Planpara">
              {premiumType ? (
                <>
                  We noticed you're currently on a {getPlanName()}  plan.<br />
                  Upgrade from {getPlanName()}  to CB Plus or CB Ent+Emp and view insights of your partner. Expand your reach and maximize the ClearBiz platform's potential.
                </>
              ) : (
                <>
                  We noticed you're currently on a CB Pro plan.<br />
                  Upgrade from CB Pro to CB Plus or CB Ent+Emp and rate users across the country. Expand your reach and maximize the ClearBiz platform's potential.
                </>
              )}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions >
          <Box width="100%" display="flex" justifyContent="center" gap={"20%"} sx={{ pb: 2 }}>
            <Button variant="contained" href="/about/pricing" target="_blank" sx={{ backgroundColor: '#2b3257', borderRadius: '15px', '&:hover': { backgroundColor: 'primary.main', boxShadow: 'none', color: "white" }, }}>
              Compare Plans
            </Button>
            <Button variant="contained" className="UpgradeBtn" component="a" href="https://wa.me/message/YVSLTCOJGS7SB1" target="_blank" sx={{ backgroundColor: '#2b3257', borderRadius: '15px', '&:hover': { boxShadow: 'none', color: "white" }, }} >
              Contact Sales
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};



export default PlanUpgradeModel;

PlanUpgradeModel.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};