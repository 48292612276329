import React,{useEffect,useState} from "react";
import { Avatar, Box, Typography, Rating, useMediaQuery } from "@mui/material";
import SideBar from "../../../components/sidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import {getEmployeeRatingAction,globalConfigAction} from "../../../store/actions"
import PropTypes from 'prop-types';
import VerifiedIcon from '@mui/icons-material/Verified';

function EmployeeListCard({ employeeDetails}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [designationOptions, setDesignationOptions] = useState([]);

  const { successData: globalSuccessData, } = useSelector((state) => state.globalConfigData) || {};  
  const isMobile = useMediaQuery("(max-width:600px)");

  const userDetailString = localStorage.getItem("UserDetail");
  const userDetail = userDetailString ? JSON.parse(userDetailString) : {};

  const businessId = userDetail.business.id;
  const currentOrganizationId = employeeDetails?.current_experience?.organization;
  const currentExperienceId =  employeeDetails?.current_experience?.id;


  console.log("employeeDetails",employeeDetails);
  

  const handleEdit = (Id) => {
    dispatch(getEmployeeRatingAction({
      endPoint: `employee-experience/${Id}/`,
    }));
    navigate('/employeeEdit', { state: { Id,employeeDetails} });
  };

  useEffect(() => {
    dispatch(globalConfigAction());
  }, [dispatch]);

  useEffect(() => {
    if(globalSuccessData){
        setDesignationOptions(globalSuccessData.employee_designation);
        
    }
  },[globalSuccessData])

  const getDesignationName = (designationId, designationOptions) => {
    const designation = designationOptions.find(option => option.id === designationId);
    return designation ? designation.name : '';
  };
  
  return (
    <>
      <SideBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: isMobile ? "86%" : "65%",
          padding: "20px",
          margin: "20px auto",
          marginLeft: isMobile ? '8%' : '20%'
        }}
      >
        {/* Avatar Section */}
        <Avatar
          src={employeeDetails?.profile_photo}
          alt="Employee Photo"
          sx={{
            width: "80px",
            height: "80px",
            marginBottom: "10px"
          }}
        />

        {/* Personal Details Section */}
        <Box sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end"
        }}>
          <Box sx={{ width: isMobile ? "100%" : "80%" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1, marginBottom: "15px" }}>
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Personal Details
              </Typography>
            </Box>
            <Box sx={{
              display: "grid",
              gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
              gap: "15px",
              padding: "0 20px",
            }}>
              <Box>
                <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Name</Typography>
                <Typography>{employeeDetails?.first_name}</Typography>
              </Box>
              <Box>
                <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Father's Name</Typography>
                <Typography>{employeeDetails?.employee_profile.father_name}</Typography>
              </Box>
              <Box>
                <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Date of Birth</Typography>
                <Typography>{employeeDetails?.employee_profile.date_of_birth}</Typography>
              </Box>
              <Box>
                <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Mobile Number</Typography>
                <Typography>{employeeDetails?.phone_number}</Typography>
              </Box>
              <Box>
                <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Email</Typography>
                <Typography>{employeeDetails?.email}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Organization Details Section */}
        {employeeDetails?.current_experience ? (
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end"
          }}>
            <Box sx={{ width: isMobile ? "100%" : "80%" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1, marginTop: "15px", marginBottom: "15px" }}>
                <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  Current Organization Details
                </Typography>
                {businessId === currentOrganizationId && (
                  <EditIcon
                    sx={{
                      cursor: 'pointer',
                      color: '#65A3DD',
                      '&:hover': { color: '#1584D4' }
                    }}
                    onClick={() => handleEdit(currentExperienceId)}
                  />
                )}
              </Box>
              <Box sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                gap: "15px",
                padding: "0 20px",
              }}>
                <Box>
                  <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Company Name</Typography>
                  <Typography>{employeeDetails?.current_experience.business_name}</Typography>
                </Box>
                <Box>
                  <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Designation</Typography>
                  <Typography>{getDesignationName(employeeDetails?.current_experience.designation, designationOptions)}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Work Period</Typography>
                  <Typography>{`${new Date(employeeDetails?.current_experience.start_date).getFullYear()}`}-Present</Typography>
                </Box>
                {employeeDetails?.current_experience.is_verified ?
                  (<Box>
                    <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Rating</Typography>
                    <Rating value={parseInt(employeeDetails?.current_experience.rating)} readOnly size="small" />
                  </Box>) :
                  (<Box sx={{ display: 'flex', alignItems: "center" }}>
                    <VerifiedIcon sx={{ color: "red", fontSize: '16px' }} />
                    <Typography sx={{ color: 'red', fontWeight: 'bold', fontSize: '10px', marginRight: '-5%' }}>Not Verified</Typography>
                  </Box>)
                }
                <Box sx={{ gridColumn: isMobile ? "1" : "1 / span 2" }}>
                  <Typography sx={{ color: "#65A3DD", fontSize: "14px" }}>Remarks</Typography>
                  <Typography sx={{ wordBreak: 'break-word', lineHeight: 1.5 }}>{employeeDetails?.current_experience.review}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{
            width: "100%",
            textAlign: "center",
            marginTop: "20px"
          }}>
            <Typography variant="h6" sx={{ color: "#666",fontWeight:"600" }}>
              You don't have any current experience
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

export default EmployeeListCard;

EmployeeListCard.propTypes = {
  employeeDetails: PropTypes.string.isRequired,
};
