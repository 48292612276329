import sliceCreator from '../index.js';
import {PRELINKPARTNERINFO} from "../../../constants"
const _getPreLinkPartnerInfo = sliceCreator('PRELINKPARTNERINFO', PRELINKPARTNERINFO, 'get');

const { asyncAction: getPreLinkPartnerInfo, reducer, clearData: clearGetPreLinkPartnerInfo } = _getPreLinkPartnerInfo;

export default reducer;

export {
    getPreLinkPartnerInfo,
    clearGetPreLinkPartnerInfo
}


