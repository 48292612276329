import React from 'react'
import Sidebar from '../../../components/sidebar'
import {Box,Button,Grid,Typography,Stack,Avatar} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

function AddToOrganization() {
  const navigate = useNavigate();

  const {state} = useLocation();

  const userData = state?.data?.has_experience_in_org

  const initialValues = {
    name: state?.data?.first_name,
    email: state?.data?.email,
    phone: state?.data?.phone_number,
    fatherName: state?.data?.employee_profile.father_name,
    DOB: state?.data?.employee_profile.date_of_birth,
    employeeState: state?.data?.employee_profile.state,
    city: state?.data?.employee_profile.city,
    distric: state?.data?.employee_profile.district,
  };
  
  const handleViewDetails = () => {
    navigate('/employeeDetails', { state: { Id: state?.data.id } });
  }

  const handleAddToOrganization = () => {
    navigate('/AddEmployeeExperience',{ state: { Id: state?.data.id } })
  }

  return (
    <>
    <Sidebar/>
    <Box component="main" sx={{ flexGrow: 1, p: 3 }} mt={8} ml={{xs:'3%',sm:"13%"}}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          mt={2}
          ml={2}
          style={{ textAlign: "center" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h5">Search Results:</Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            mt={2}
            ml={2}
            style={{ textAlign: "center" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={2}
            >
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
                marginBottom="5px"
              >
                <Avatar
                src={state?.data?.profile_photo}
                  sx={{
                    width: 70,
                    height: 70,
                    border: '2px solid black',
                  }}
                >
                </Avatar>
              </Stack>
              <Grid container spacing={3} sx={{ maxWidth: '100%', margin: '0 auto' }}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="name"
                      className="form-label"
                      style={{ textAlign: "start" }}
                    >
                      Full Name
                    </label>
                  </div>

                  <input
                    id="name"
                    value = {initialValues.name}
                    type="text"
                    readOnly
                    className="form-control"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="fatherName"
                      style={{ textAlign: "start" }}
                      className="form-label"
                      
                    >
                      Father Name
                    </label>
                  </div>
                  <input
                    id = "fatherName"
                    type="text"
                    value = {initialValues.fatherName}
                    readOnly
                    className="form-control"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="DOB"
                      style={{ textAlign: "start" }}
                      className="form-label"
                      
                    >
                      Date Of Birth
                    </label>
                  </div>
                  <input
                    id = "DOB"
                    value = {initialValues.DOB}
                    type="text"
                    readOnly
                    className="form-control"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="phone"
                      style={{ textAlign: "start" }}
                      className="form-label"
                      
                    >
                      Mobile Number
                    </label>
                  </div>
                  <input
                    id = "phone"
                    type="text"
                    value = {initialValues.phone}
                    readOnly
                    className="form-control"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="email"
                      style={{ textAlign: "start" }}
                      className="form-label"
                      
                    >
                      Email ID
                    </label>
                  </div>
                  <input
                    id = "email"
                    type="text"
                    value = {initialValues.email}
                    readOnly
                    className="form-control"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="city"
                      style={{ textAlign: "start" }}
                      className="form-label"
                      
                    >
                      {" "}
                      City
                    </label>
                  </div>
                  <input
                    id  = "city"
                    value = {initialValues.city}
                    type="text"
                    readOnly
                    className="form-control"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="distric"
                      style={{ textAlign: "start" }}
                      className="form-label"
                      
                    >
                      District
                    </label>
                  </div>
                  <input
                    id = "distric"
                    value = {initialValues.distric}
                    type="text"
                    readOnly
                    className="form-control"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="employeeState"
                      style={{ textAlign: "start" }}
                      className="form-label"
                      
                    >
                      State
                    </label>
                  </div>
                  <input
                    id = "employeeState"
                    value = {initialValues.employeeState}
                    type="text"
                    readOnly
                    className="form-control"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Stack direction={"row"} justifyContent="center">
          <Button
            variant="secondary"
            className="card-link"
            onClick={handleViewDetails}
            style={{ marginTop: "30px", width: "200px", background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',color:'white' }}
          >
            View Details
          </Button>
          {!userData && (
            <Button
              variant="secondary"
              className="card-link"
              onClick={handleAddToOrganization}
              style={{ marginTop: "30px", width: "200px", background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',color:'white' }}
            >
              Add To Organization
            </Button>
          )}
        </Stack>
      </Box>
    </>
  )
}

export default AddToOrganization