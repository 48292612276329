import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography, Button, Rating } from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';
import SideBar from "../../../components/sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeRatingAction, globalConfigAction } from "../../../store/actions";
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import Loader from "../../../components/loader";

function EmployeeListCard({ hideButton, employees, isTransitional,loading  }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [designationOptions, setDesignationOptions] = useState([]);

  const { successData: globalSuccessData } = useSelector((state) => state.globalConfigData) || {};
  const isDetailsPage = location.pathname === '/employeeDetails';

  const handleViewEdit = (employeeId) => {
    if (isDetailsPage) {
      navigate('/employeeEdit');
    } else {
      navigate('/employeeDetails', { state: { Id: employeeId } });
    }
  };

  const handleISExist = (Id) => {
    dispatch(getEmployeeRatingAction({
      endPoint: `employee-experience/${Id}/`,
    }));
    navigate('/verifyEmployee', { state: { Id } });
  };



  useEffect(() => {
    dispatch(globalConfigAction());
  }, [dispatch]);

  useEffect(() => {
    if (globalSuccessData) {
      setDesignationOptions(globalSuccessData.employee_designation);
    }
  }, [globalSuccessData]);

  const getDesignationName = (designationId, designationOptions) => {
    const designation = designationOptions.find(option => option.id === designationId);
    return designation ? designation.name : '';
  };

  const formatDate = (dateString,is_current) => {
    if (is_current && !dateString) {
      return 'Present';
    }
    
    if (!dateString && !is_current) {
      return 'null';
    }
    
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

  const handleEdit = (employeeId) => {
    dispatch(getEmployeeRatingAction({
      endPoint: `employee-experience/${employeeId}/`,
    }));
    navigate('/employeeEdit', { state: { Id: employeeId } });
  };

  const renderVerificationStatus = (isVerified, experience) => {
    return isVerified ? (
      <Rating value={parseInt(experience.rating)} readOnly size="small" />
    ) : (
      <Box sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center', gap: 0.5 }}>
        <VerifiedIcon sx={{ color: 'red', fontSize: '16px' }} />
        <Typography variant="caption" sx={{ color: 'grey' }}>
          Not Verified
        </Typography>
        <EditIcon 
        sx={{ 
          color: '#65A3DD', 
          fontSize: '16px',
          cursor: 'pointer'
        }} 
        onClick={() => handleEdit(experience.id)}
      />
      </Box>
    );
  };
  
  const renderEmployeeList = () => {
    if (loading) {
        return <Loader className="loader" />;
    }

    if (!employees?.length) {
      return (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
          width: '80%',
          margin: { xs: '0 5%', sm: '0 10%', md: '0 15%' }
        }}>
          <Typography variant="h5" sx={{
            color: '#65A3DD',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem' }
          }}>
            No Employees Present..!
          </Typography>
        </Box>
      );
    }
  
    return employees?.map((employee) => {
      const hasMultipleExperiences = employee.experiences.length > 1;
  
      if (hasMultipleExperiences) {
        return (
          <Box
            key={employee.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#fff",
              padding: { xs: '4%', sm: '3%', md: '2%' },
              borderRadius: "25px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              width: { xs: '90%', sm: '80%', md: '75%' },
              margin: { xs: '10px auto', md: '20px auto' },
              ml: { xs: '10%', sm: '20%', md: '20%' },
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              mb: 3,
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 2, sm: 0 }
            }}>

              <Avatar
                src={employee.profile_photo || ''}
                alt={employee.name}
                sx={{
                  width: { xs: '60px', sm: '70px', md: '80px' },
                  height: { xs: '60px', sm: '70px', md: '80px' },
                  marginRight: { xs: '0', sm: '15px' },
                  border: '1px solid #000',
                }}
              />
              <Typography sx={{ 
                fontWeight: "bold",
                fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.3rem' }
              }}>
                {employee.first_name}
              </Typography>
            </Box>
  
            {employee.experiences.map((experience, index) => (
              <Box
                key={experience.id}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'space-between',
                  alignItems: { xs: 'flex-start', md: 'center' },
                  padding: { xs: '15px 10px', md: '10px' },
                  borderBottom: index !== employee.experiences.length - 1 ? '1px solid #eee' : 'none',
                  gap: { xs: 2, md: 0 }
                }}
              >
                <Box sx={{ 
                  width: { xs: '100%', md: '30%' }, 
                  textAlign: { xs: 'left', md: 'center' }
                }}>
                  <Typography sx={{ 
                    color: '#65A3DD', 
                    mb: 1,
                    fontSize: { xs: '0.9rem', md: '1rem' }
                  }}>
                    Designation
                  </Typography>
                  <Typography fontWeight='bold' fontSize={{ xs: '0.85rem', md: '0.9rem' }}>
                    {getDesignationName(experience?.designation, designationOptions)}
                  </Typography>
                </Box>
  
                <Box sx={{ 
                  width: { xs: '100%', md: '30%' }, 
                  textAlign: { xs: 'left', md: 'center' }
                }}>
                  <Typography sx={{ 
                    color: '#65A3DD', 
                    mb: 1,
                    fontSize: { xs: '0.9rem', md: '1rem' }
                  }}>
                    Work Period
                  </Typography>
                  <Typography 
                    variant="caption" 
                    fontWeight='bold' 
                    color='#000'
                    fontSize={{ xs: '0.85rem', md: '0.9rem' }}
                  >
                    {formatDate(experience?.start_date)} to {formatDate(experience?.end_date, experience?.is_current)}
                  </Typography>
                </Box>
  
                <Box sx={{ 
                  width: { xs: '100%', md: '30%' }, 
                  textAlign: { xs: 'left', md: 'center' }
                }}>
                  <Typography sx={{ 
                    color: '#65A3DD', 
                    mb: 1,
                    fontSize: { xs: '0.9rem', md: '1rem' }
                  }}>
                    {experience.is_verified ? "Rating" : ""}
                  </Typography>
                  {renderVerificationStatus(experience.is_verified, experience)}
                </Box>
              </Box>
            ))}
  
            {!hideButton && (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: { xs: 'center', md: 'flex-end' },
                mt: 3,
                gap: 2,
                flexDirection: { xs: 'column', sm: 'row' },
                width: { xs: '100%', sm: 'auto' }
              }}>
                {isTransitional ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => handleViewEdit(employee.id)}
                      sx={{
                        background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: "20px",
                        padding: "5px 15px",
                        width: { xs: '100%', sm: '100px' },
                        ":hover": {
                          backgroundColor: "#357ABD",
                        },
                      }}
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: "20px",
                        padding: "5px 15px",
                        width: { xs: '100%', sm: '100px' },
                        ":hover": {
                          backgroundColor: "#357ABD",
                        },
                      }}
                    >
                      Is Exist
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => handleViewEdit(employee.id)}
                    sx={{
                      background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                      color: "#fff",
                      textTransform: "none",
                      borderRadius: "20px",
                      padding: "5px 15px",
                      width: { xs: '100%', sm: '200px', md: '20%' },
                      ":hover": {
                        backgroundColor: "#357ABD",
                      },
                    }}
                  >
                    {isDetailsPage ? 'Edit' : 'View/Edit'}
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      }
  
      return (
        <Box
          key={employee.id}
          sx={{
            display: "flex",
            flexDirection: { xs: 'column', md: 'row' },
            backgroundColor: "#fff",
            padding: { xs: '4%', sm: '3%', md: '2%' },
            borderRadius: "25px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            width: { xs: '90%', sm: '70%', md: '75%' },
            margin: { xs: '10px auto', md: '20px auto' },
            ml: { xs: '5%', sm: '27%', md: '20%' },
            '@media (min-width: 900px) and (max-width: 970px)': {
              width: '80%',
            },
          }}
        >
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            width: { xs: '100%', md: 'auto' },
            mb: { xs: 2, md: 0 }
          }}>
            <Avatar
              src={employee.profile_photo || ''}
              alt={employee.name}
              sx={{
                width: { xs: '50px', sm: '60px', md: '60px' },
                height: { xs: '50px', sm: '60px', md: '60px' },
                marginRight: "15px",
                border: '1px solid #000',
              }}
            />
            <Typography sx={{ 
              fontWeight: "bold",
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
              width: { xs: '150px', md: '150px' }, // Set a fixed width for the name container
            }}>
              {employee.first_name}
            </Typography>
          </Box>
  
          <Box sx={{ 
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 2, md: 4 },
            flex: 1,
            ml: { xs: 0, md: 4 },
            alignItems: { xs: 'flex-start', md: 'center' }
          }}>
            <Box sx={{ width: { xs: '100%', md: '150px' }, margin: '0 auto' }}>
              <Typography sx={{ 
                color: '#65A3DD',
                fontSize: { xs: '0.9rem', md: '1rem' },
              }}>
                Designation
              </Typography>
              {employee.experiences.map((experience) => (
                <Typography 
                  key={experience.id} 
                  fontSize={{ xs: '0.8rem', md: '0.9rem' }}
                  fontWeight='bold'
                >
                  {getDesignationName(experience?.designation, designationOptions)}
                </Typography>
              ))}
            </Box>
  
            <Box sx={{ width: { xs: '100%', md: '150px' }, margin: '0 auto'  }}>
              <Typography sx={{ 
                color: '#65A3DD',
                fontSize: { xs: '0.9rem', md: '1rem' },
              }}>
                Work Period
              </Typography>
              {employee.experiences.map((experience) => (
                <Typography 
                  key={experience.id} 
                  variant="caption" 
                  fontWeight='bold'
                  fontSize={{ xs: '0.8rem', md: '0.9rem' }}
                >
                  {formatDate(experience?.start_date)} to {formatDate(experience?.end_date, experience?.is_current)}
                </Typography>
              ))}
            </Box>
  
            <Box sx={{ width: { xs: '100%', md: '150px' },margin: '0 auto' }}>
              {employee?.experiences.map((experience, index) => (
                <Box key={experience.id}>
                  {experience.is_verified && (
                    <Typography sx={{
                      background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontSize: { xs: '0.9rem', md: '1rem' }
                    }}>
                      Rating
                    </Typography>
                  )}
                  {renderVerificationStatus(experience.is_verified, experience)}
                </Box>
              ))}
            </Box>
  
            {!hideButton && (
              <Box sx={{
                mt: { xs: 2, md: 0 },
                width: { xs: '100%', md: 'auto' },
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-end' }
              }}>
                {isTransitional && employee.experiences.some(experience => !experience.verified_exit) ? (
                  <Box sx={{ display: 'flex', gap: 1, flexDirection: { xs: 'column', sm: 'row', md :"column" }, width: '100%', justifyContent: 'center' }}> 
                    <Button
                      variant="contained"
                      onClick={() => handleViewEdit(employee.id)}
                      sx={{
                        background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: "20px",
                        padding: "2px",
                        width: { xs: '100%', sm: '150px', md: '100px' },
                        ":hover": {
                          backgroundColor: "#357ABD",
                        },
                      }}
                    >
                      View
                    </Button>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: { xs: '100%', sm: 'auto' } }}>
                      {employee.experiences.map((experience) => (
                        !experience.exit_verified && (
                          <Button
                            key={experience.id}
                            variant="contained"
                            onClick={handleISExist.bind(null, experience.id)}
                            sx={{
                              background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                              color: "#fff",
                              textTransform: "none",
                              borderRadius: "20px",
                              padding: "2px",
                              width: { xs: '100%', sm: '150px', md: '100px' },
                              ":hover": {
                                backgroundColor: "#357ABD",
                              },
                            }}
                          >
                            Verify Exist
                          </Button>
                        )
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => handleViewEdit(employee.id)}
                    sx={{
                      background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                      color: "#fff",
                      textTransform: "none",
                      borderRadius: "20px",
                      padding: "5px 15px",
                      width: { xs: '100%', sm: '200px', md: '100px' },
                      fontSize: { xs: '0.8rem', md: '0.9rem' },
                      ":hover": {
                        backgroundColor: "#357ABD",
                      }
                    }}
                  >
                    {isDetailsPage ? 'Edit' : 'View/Edit'}
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>
      );
    });
  };
  

  return (
    <>
      <SideBar />
      {renderEmployeeList()}
    </>
  );
}

EmployeeListCard.propTypes = {
  hideButton: PropTypes.bool,
  employees: PropTypes.array.isRequired,
  isTransitional: PropTypes.bool,
  EmployeeDetails: PropTypes.object.isRequired,
};

export default EmployeeListCard;
