import PropTypes from "prop-types"
import React from 'react';
import { Box, Button, Card, CardContent, TextField, Typography,Grid } from '@mui/material';

function GstRegistration({handleFormChange, gst, handleChange, error, handleSubmit, handleLogin}) {
  
    return (
        <div>
 <Box sx={{ minHeight: "100vh"}}>
 
  <Grid container justifyContent={'center'} >
  <Grid item xs={10} lg={6} marginTop={{xs:'20%',sm:'0%'}}>
  <img src="/images/clearbiz1.png"  alt=""  style={{width:'50%',marginLeft:'22%',marginTop:'5%',marginBottom:'2%'}}/> 
  <Card elevation={20}   >
    <CardContent justifyContent={'center'}>
    <Box sx={{display:'flex',justifyContent:'space-between'}}>
    <Typography sx={{color:'#65A3DD',fontWeight:'bold',fontSize:{xs:'18px',sm:'25px'}}}>
              Register to ClearBiz
     </Typography>
     <a href="/employeeRegistration">
     <Typography sx={{fontSize:{xs:'10px',sm:'20px',color:'#1b57c2',fontWeight:'bold','@media(min-width:400px) and (max-width:600px)':{fontSize:'15px'}}}}>
      Register as Employee
      </Typography>
     </a>
     </Box>
        <TextField
          fullWidth
          margin="normal"
          label="GSTIN Number"
          name="gst"
          variant="outlined"
          value={gst}
          onChange={(e) => handleChange({ ...e, target: { ...e.target, value: e.target.value.trim() } })}
          onInput={(e) =>
            (e.target.value = ("" + e.target.value).toUpperCase())
          }
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !error) {
              handleSubmit();
            }
          }}
          helperText={error}
          error={!!error}
        />
        <Button variant="contained" sx={{background:'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)'}} onClick={() => {handleSubmit()}}>
          Submit
        </Button>
      <Box mt={2}>
        <Typography variant="body2">
          Don't have a GSTIN?
          <Button onClick={()=>{
            handleFormChange();
          }} color="primary" sx={{fontSize:{xs:'12px',sm:'15px'}}}>
            Register with PAN
          </Button>
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body2">
        Already on ClearBiz?
          <Button onClick={()=>{
            handleLogin();
          }} color="primary">
             Login
          </Button>
        </Typography>
      </Box>
    </CardContent>
  </Card>
  </Grid>
  </Grid>
</Box>
        </div>
    );
}

export default GstRegistration;

GstRegistration.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleLogin:PropTypes.func.isRequired,
  gst: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
 
 }