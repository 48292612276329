export const BASE_URL = "http://devapi.clearbiz.ai/api/v1/";
export const STATIC_BASE_URL = "http://devapi.clearbiz.ai/";
export const SITE_KEY = "6Lcr0SQqAAAAAACapapIkFD-oFxQ_GRBh_i6c3Q9"

export const LOGIN = "login/";
export const REGISTRATION = "registration/";
export const SEARCH_PARTNER = "searchPartner/";
export const MY_PARTNER = "myPartners/";
export const MY_PARTNER_RATINGS = "getPartnerRatings/";
export const MY_RATINGS = "myRatings/";
export const GLOBAL_CONFIG = "globalConfig/";
export const RAISED_DISPUTE = "raiseDispute/";
export const PROFILE = "profile/";
export const NOTIFICATIONS = "notifications/";
export const PAYMENTS = "payments/";
export const PAYABLE_AMOUNT = 1999;
export const TESTIMONIALS = "testimonial/"



export const PASSWORD_RESET = "password_reset/";

export const GST_DETAILS = "gstSearch/";
export const SUBSCRIPTION = "subscriptions/";
export const FORGOT_PASSWORD = "forgot-password/";
export const VERIFY_PASSWORD = "verify-password-reset/";
export const SEND_OTP = "resend/";
export const VERIFY_OTP = "verify/";
export const LOGIN_OTP = "loginWithOTP/";

export const ADMIN_DASHBOARD = "admin/admin_dashboard/";
export const ADMIN_PAYMENTS = "admin/payments/";
export const USER_SEARCH = "admin/userSearch/";
export const Edit_Business = "admin/edit-business";
export const Edit_User = "admin/edit-users"
export const Edit_Received_Rating = "admin/edit-received-rating"
export const Edit_given_Rating = "admin/edit-given-rating"
export const USERSEARCHBYLOCATION = "admin/userSearchByLocation/"
export const INACTIVE_USERS = "admin/inactive-users/"
export const RELETEDPAN = "admin/linkedRelationship/"

export const LINKED_PAN = "linkedRelationship/"
export const ADMIN_USER_RATINGS_DETAILS = "admin/admin-partner-ratings/"
export const ADMIN_INACTIVE_USERS_NOTIFICATION = "admin/inactive-user-notifications/send_notifications/"
export const BULK_UPLOAD = "bulkrating/"
export const EMPLOYEE_SEARCH = "searchEmployee/"
export const EMPLOYEE_RATING = "employee-experience/"
export const EMPLOYEE_DETAILS = "employee-details/"
export const EMPLOYEE_DASHBOARD = "business-employees/"
export const EMPLOYEE_EXPERIENCE = "employee-experience/"
export const BUSINESS_NAME_SEARCH = "business-autocomplete/"
export const PRELINKPARTNERINFO = "pre-link-partner-info/"
export const SEND_RATING_SMS ="/send-rating-sms/"
export const PROFILE_UPLOAD = "upload-profile-picture/"

export const navList = [
  {
    title: "Dashboard",
    goTo: "/dashboard",
    icon: "fa fa-th",
  },

  {
    title: "My Ratings",
    goTo: "/myratings",
    icon: "fa fa-check-circle",
  },
  {
    title: "Disputes",
    goTo: "/disputes",
    icon: "fa fa-hand-paper-o",
  },
  // {
  //   title: "Related Pan",
  //   goTo: '/reletedPan',
  //   icon: "fa fa-user",
  // },
];
