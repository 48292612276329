import React from 'react';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import paymentSuccess from '../../assets/paymentSuccess.png';
import Box from '@mui/material/Box';


const PaymentSuccessPopup = ({handleClose}) => {
 
  
 

 

  const navigateToLogin = () => {
    handleClose(); // Close the dialog
  };

  return ( 
      <Box width="100%" paddingTop={{xs:-20,sm:-30}}>
        <DialogContent>
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <img src={paymentSuccess} alt="Success GIF" style={{ height: '30%', width: '30%'}} />
          </div>
          <div style={{ textAlign: 'center' }}>
          <Typography sx={{fontSize:{xs:20,sm:32,md:32},fontWeight:'bold',background: 'linear-gradient(270.08deg, #2D5E2B -3.33%, #38A234 99.87%)',WebkitBackgroundClip:'text',WebkitTextFillColor:'transparent'}}>
            Transaction updated successfully!</Typography>
            <Typography sx={{fontSize:{xs:14,sm:20,md:20},fontWeight:'bold',background: 'linear-gradient(270.08deg, #2D5E2B -3.33%, #38A234 99.87%)',WebkitBackgroundClip:'text',WebkitTextFillColor:'transparent'}}>
           Your account will be activated in 2 working days.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={navigateToLogin}  variant="contained" sx={{alignSelf:'center',justifyContent:'center',mr:'40%',background: 'rgba(3, 121, 3, 1)',width:'20%','&:hover':{background: 'rgba(3, 121, 3, 0.9)'}}}>
            OK
          </Button>
        </DialogActions>
        </Box>
  );
};

export default PaymentSuccessPopup;


PaymentSuccessPopup.propTypes = {
  handleClose: PropTypes.func
}

PaymentSuccessPopup.defaultProps = {
  handleClose: () => {}
}

