import { useState, useEffect, useRef } from "react";
import { Box, Typography, TextField, Button, Grid } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  sendOtpAction,
  verifyOtpAction,
  clearSendOtpData,
  clearVerifyOtpData,
  getProfileAction,
  putProfileAction,
  clearPutProfileActionData,
  postProfileUploadAction,
} from "../../../store/actions";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from 'prop-types';



const FormField = ({ name, label, type = "text", readOnly = false, ...props }) => {
  const { isEditMode, isEditEmailMode, isEditNumberMode, setIsEditEmailMode, setIsEditNumberMode,
    phoneOtpSent, emailOtpSent, phoneOtpTimer, emailOtpTimer, sendOtpLoading, verifyOtpLoading,
    handleSendOtp, handleVerifyOtp, setFieldValue, isEmailVerified, isPhoneVerified
  } = props;

  return (
    <Box sx={{ width: "100%" }}>
      <Typography component="label" display="block" sx={{ mb: 1 }}>
        {label}
        {name === "email" && isEditMode && !isEditEmailMode && !isEmailVerified && (
          <button
            style={{
              color: "#2b4257",
              border: "none",
              cursor: "pointer",
              borderRadius: "4px",
              marginLeft: "5px",
              textDecoration: "underline",
            }}
            onClick={() => setIsEditEmailMode(true)}
          >
            Change Email Id
          </button>
        )}
        {name === "phone" && isEditMode && !isEditNumberMode && !isPhoneVerified && (
          <button
            style={{
              color: "#2b4257",
              border: "none",
              cursor: "pointer",
              borderRadius: "4px",
              marginLeft: "5px",
              textDecoration: "underline",
            }}
            onClick={() => setIsEditNumberMode(true)}
          >
            Change Number
          </button>
        )}
      </Typography>

      <Field name={name}>
        {({ field, meta }) => (
          <>
            <TextField
              {...field}
              type={type}
              fullWidth
              size="small"
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
              InputProps={{
                readOnly: readOnly ||
                  (!isEditMode && name !== "email" && name !== "phone") ||
                  (name === "email" && (!isEditEmailMode || isEmailVerified)) ||
                  (name === "phone" && (!isEditNumberMode || isPhoneVerified))
              }}
            />

            {/* Email OTP section */}
            {name === "email" && isEditEmailMode && !isEmailVerified && !emailOtpSent && (
              <Box>
                <button
                  type="button"
                  className="signup-otp-button"
                  disabled={sendOtpLoading}
                  onClick={() => handleSendOtp("email")}
                >
                  {sendOtpLoading ? <CircularProgress size={24} style={{ color: "#fff" }} /> : "Send OTP"}
                </button>
              </Box>
            )}

            {name === "email" && isEditEmailMode && !isEmailVerified && emailOtpSent && (
              <Box>
                <TextField
                  name="emailOtp"
                  placeholder="Enter OTP"
                  onChange={(e) => setFieldValue("emailOtp", e.target.value)}
                  sx={{ mt: 1 }}
                />
                <button
                  type="button"
                  className="signup-otp-button"
                  disabled={verifyOtpLoading}
                  onClick={() => handleVerifyOtp("email")}
                >
                  Verify OTP
                </button>
                <Typography>
                  Time remaining: {Math.floor(emailOtpTimer / 60)}:
                  {emailOtpTimer % 60 < 10 ? "0" : ""}
                  {emailOtpTimer % 60}
                </Typography>
              </Box>
            )}

            {/* Phone OTP section */}
            {name === "phone" && isEditNumberMode && !isPhoneVerified && !phoneOtpSent && (
              <Box>
                <button
                  type="button"
                  className="signup-otp-button"
                  disabled={sendOtpLoading}
                  onClick={() => handleSendOtp("phone")}
                >
                  {sendOtpLoading ? <CircularProgress size={24} style={{ color: "#fff" }} /> : "Send OTP"}
                </button>
              </Box>
            )}

            {name === "phone" && isEditNumberMode && !isPhoneVerified && phoneOtpSent && (
              <Box>
                <TextField
                  name="phoneOtp"
                  placeholder="Enter OTP"
                  onChange={(e) => setFieldValue("phoneOtp", e.target.value)}
                  sx={{ mt: 1 }}
                />
                <button
                  type="button"
                  className="signup-otp-button"
                  disabled={verifyOtpLoading}
                  onClick={() => handleVerifyOtp("phone")}
                >
                  Verify OTP
                </button>
                <Typography>
                  Time remaining: {Math.floor(phoneOtpTimer / 60)}:
                  {phoneOtpTimer % 60 < 10 ? "0" : ""}
                  {phoneOtpTimer % 60}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Field>
    </Box>
  );
};


const personalInfoValidationSchema = Yup.object({
  fullName: Yup.string().required("Name is required"),
  fatherName: Yup.string().required("Father name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Phone number should be exactly 10 digits")
    .required("Phone number is required"),
  dateOfBirth: Yup.date().required("Date of birth is required"),
  city: Yup.string().required("City is required"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("State is required"),
});

const EmployeeProfile = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ profileImagePreview: null });
  const [errors, setErrors] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditEmailMode, setIsEditEmailMode] = useState(false);
  const [isEditNumberMode, setIsEditNumberMode] = useState(false);
  const [phoneOtpSent, setPhoneOtpSent] = useState(false);
  const [phoneOtpTimer, setPhoneOtpTimer] = useState(300);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [emailOtpSent, setEmailOtpSent] = useState(false);
  const [emailOtpTimer, setEmailOtpTimer] = useState(300);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [verifyIdentifier, setVerifyIdentifier] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [initialValues, setInitialValues] = useState({
    fullName: '',
    fatherName: '',
    email: '',
    phone: '',
    dateOfBirth: '',
    city: '',
    district: '',
    state: '',
    pan: '',
    emailOtp: '',
    phoneOtp: ''
  });

  const formikRef = useRef(null);

  const { successData: sendOtpSuccessData, error: sendOtpError, errorInfo: sendOtpErrorInfo } = useSelector((state) => state.sendOtpReducer) || {};
  const { successData: verifyOtpSuccessData, error: verifyOtpError, errorInfo: verifyOtpErrorInfo } = useSelector((state) => state.verifyOtpReducer) || {};
  const { successData: profileData } = useSelector(state => state.profileData) || {};
  const { successData: profilePictureUploadSuccessData, error: ProfilePictureUploadError, errorInfo: ProfilePictureUploadErrorInfo, loading } = useSelector(state => state.postProfileUploadReducer) || {};

  const {
    successData: updateProfileData,
    error: updateError,
    errorInfo: updateErrorInfo,
  } = useSelector((state) => state.updateProfileData) || {};

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setErrors((prev) => ({
          ...prev,
          profileImage: "File size should be less than 5MB",
        }));
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(file);
        setFormData((prev) => ({
          ...prev,
          profileImagePreview: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSendOtp = (identifier) => {
    if (!formikRef.current) return;

    const formValues = formikRef.current.values;
    setIdentifier(identifier);
    const value = identifier === 'phone' ? formValues.phone : formValues.email;
    dispatch(sendOtpAction({ identifier: value }));
  };

  const handleVerifyOtp = (identifier) => {
    const formValues = formikRef.current.values;
    setVerifyIdentifier(identifier);
    const value = identifier === 'phone' ? formValues.phone : formValues.email;
    const otp = identifier === 'phone' ? formValues.phoneOtp : formValues.emailOtp;

    dispatch(verifyOtpAction({
      identifier: value,
      otp: otp
    }));
  };


  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEditMode(true);
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (!isEditMode) {
      setSubmitting(false);
      return;
    }

    const userData = {
      user: {
        email: values.email,
        first_name: values.fullName,
        phone_number: values.phone
      },
      employee_profile: {
        father_name: values.fatherName,
        date_of_birth: values.dateOfBirth,
        city: values.city,
        district: values.district,
        state: values.state
      }
    };

    dispatch(
      putProfileAction({
        endPoint: `profile/${profileData.id}/`,
        ...userData,
      })
    );
    setSubmitting(false);
  };



  useEffect(() => {
    dispatch(getProfileAction());
  }, [dispatch]);

  useEffect(() => {
    if (profileData) {
      setInitialValues({
        fullName: profileData?.first_name || '',
        fatherName: profileData?.employee_profile?.father_name || '',
        email: profileData?.email || '',
        phone: profileData?.phone_number || '',
        dateOfBirth: profileData?.employee_profile?.date_of_birth || '',
        city: profileData?.employee_profile?.city || '',
        district: profileData?.employee_profile?.district || '',
        state: profileData?.employee_profile?.state || '',
        pan: profileData?.masked_pan || ''
      });
      if (profileData.profile_photo) {
        setFormData(prev => ({
          ...prev,
          profileImagePreview: profileData.profile_photo
        }));
      }
    }
  }, [profileData]);


  useEffect(() => {
    console.log('editmode', isEditMode)
  }, [isEditMode])

  useEffect(() => {
    dispatch(getProfileAction());
  }, [dispatch]);

  useEffect(() => {
    if (phoneOtpSent && phoneOtpTimer > 0) {
      const timer = setInterval(() => {
        setPhoneOtpTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (phoneOtpTimer === 0) {
      setPhoneOtpSent(false);
    }
  }, [phoneOtpSent, phoneOtpTimer]);

  useEffect(() => {
    if (emailOtpSent && emailOtpTimer > 0) {
      const timer = setInterval(() => {
        setEmailOtpTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (emailOtpTimer === 0) {
      setEmailOtpSent(false);
    }
  }, [emailOtpSent, emailOtpTimer]);

  useEffect(() => {
    if (sendOtpSuccessData && identifier) {
      toast.success(`OTP sent to ${identifier}`);
      if (identifier === "email") {
        setEmailOtpSent(true);
        setEmailOtpTimer(300);
      } else {
        setPhoneOtpSent(true);
        setPhoneOtpTimer(300);
      }
      setIdentifier("");
      dispatch(clearSendOtpData());
    }
  }, [sendOtpSuccessData, identifier, dispatch]);

  useEffect(() => {
    if (verifyOtpSuccessData && verifyIdentifier) {
      toast.success(`${verifyIdentifier} verified successfully`);
      if (verifyIdentifier === "email") {
        setIsEmailVerified(true);
        setEmailOtpSent(false);
      } else {
        setIsPhoneVerified(true);
        setPhoneOtpSent(false);
      }
      setVerifyIdentifier("");
      dispatch(clearVerifyOtpData());
    }
  }, [verifyOtpSuccessData, verifyIdentifier, dispatch]);

  useEffect(() => {
    console.log('Profile Data:', profileData);
    console.log('Initial Values:', initialValues);
  }, [profileData, initialValues]);

  useEffect(() => {
    if (updateProfileData) {
      toast.success("Profile updated successfully");
      setIsEditMode(false);
      dispatch(getProfileAction());
      dispatch(clearPutProfileActionData());
    }
  }, [updateProfileData, dispatch]);

  useEffect(() => {
    if (updateError) {
      toast.error(updateErrorInfo);
      dispatch(clearPutProfileActionData());
    }
  }, [updateError, updateErrorInfo, dispatch]);

  useEffect(() => {
    if (sendOtpError) {
      toast.error(sendOtpErrorInfo || "Failed to send OTP");
      dispatch(clearSendOtpData());
    }
  }, [sendOtpError, sendOtpErrorInfo, dispatch]);

  useEffect(() => {
    if (verifyOtpError) {
      toast.error(verifyOtpErrorInfo || "Failed to verify OTP");
      dispatch(clearVerifyOtpData());
    }
  }, [verifyOtpError, verifyOtpErrorInfo, dispatch]);

  const handleProfilePictureUpload = () => {
    console.log("handleProfilePictureUpload called");
    if (!profilePicture) {
      toast.error("Please select a profile picture");
      return;
    }
    const formData = new FormData();
    formData.append("profile_picture", profilePicture);
    dispatch(postProfileUploadAction(formData));
  }

  useEffect(() => {
    if (profilePictureUploadSuccessData) {
      toast.success("Profile picture uploaded successfully");
    }
    if (ProfilePictureUploadError) {
      toast.error(ProfilePictureUploadErrorInfo || "Failed to upload profile picture");
    }
  }, [profilePictureUploadSuccessData,])

  return (
    <Box sx={{ px: { xs: 2, sm: 5 }, py: 3 }}>
      <Box textAlign="center" mb={4}>
        <Typography
          sx={{
            fontWeight: "bold",
            background: "linear-gradient(90deg, #553ebd, #7a538e)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: { xs: '1.3rem', sm: '2rem' },
            marginLeft: { xs: '-10%', sm: '-10%', md: '0%' },
          }}
        >
          EMPLOYEE PROFILE
        </Typography>
      </Box>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={isEditMode ? personalInfoValidationSchema : null}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "2rem",
                maxWidth: "800px",
                minWidth: { xs: '300px' },
                marginLeft: { xs: '-30%', sm: '-30%', md: '5%' },
                mx: "auto",
              }}
            >
              <Grid item xs={12} className="text-center">
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    id="profile-image-upload"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                  />

                  <div style={{
                    width: "100px",
                    height: "100px",
                    margin: "20px auto",
                    position: "relative",
                    cursor: "pointer",
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}>
                    {loading ? (
                      <div style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        backgroundColor: "#f5f5f5",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px solid #2b4257"
                      }}>
                        <span style={{ fontSize: '10px' }}>Saving Your Photo...</span>
                      </div>
                    ) : (
                      <>
                        {formData.profileImagePreview ? (
                          <img
                            src={formData.profileImagePreview}
                            alt="Profile Preview"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              objectFit: "cover",
                              border: "2px solid #2b4257"
                            }}
                          />
                        ) : (
                          <div style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "2px solid #2b4257"
                          }}>
                            <span>Profile Photo</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {errors.profileImage && (
                    <p style={{ color: '#d32f2f', fontSize: '0.75rem', marginTop: '3px' }}>
                      {errors.profileImage}
                    </p>
                  )}
                </div>
                {isEditMode && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                    <label htmlFor="profile-image-upload">
                      <Button
                        variant="contained"
                        component="span"
                        sx={{
                          background: "linear-gradient(90deg, #553ebd, #7a538e)",
                          color: "white",
                          '&:hover': {
                            background: "linear-gradient(90deg, #4a35a8, #6a4679)"
                          },
                          fontSize: '10px',
                        }}
                      >
                        Upload Image
                      </Button>
                    </label>

                    <Button
                      onClick={handleProfilePictureUpload}
                      disabled={!profilePicture}
                      variant="contained"
                      sx={{
                        background: "linear-gradient(90deg, #2B4257, #625DBD)",
                        color: "white",
                        '&:hover': {
                          background: "linear-gradient(90deg, #243647, #524dae)"
                        },
                        fontSize: '10px',
                        mt: '-4%'
                      }}
                    >
                      Save Image
                    </Button>
                  </Box>
                )}
              </Grid>

              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                    gap: 2,
                  }}
                >
                  <FormField
                    name="fullName"
                    label="Full Name"
                    isEditMode={isEditMode}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <FormField
                    name="fatherName"
                    label="Father Name"
                    isEditMode={isEditMode}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <FormField
                    name="email"
                    label="Email"
                    type="email"
                    isEditMode={isEditMode}
                    isEditEmailMode={isEditEmailMode}
                    setIsEditEmailMode={setIsEditEmailMode}
                    emailOtpSent={emailOtpSent}
                    emailOtpTimer={emailOtpTimer}
                    handleSendOtp={handleSendOtp}
                    handleVerifyOtp={handleVerifyOtp}
                    values={values}
                    setFieldValue={setFieldValue}
                    isEmailVerified={isEmailVerified}
                  />

                  <FormField
                    name="phone"
                    label="Phone"
                    type="tel"
                    isEditMode={isEditMode}
                    isEditNumberMode={isEditNumberMode}
                    setIsEditNumberMode={setIsEditNumberMode}
                    phoneOtpSent={phoneOtpSent}
                    phoneOtpTimer={phoneOtpTimer}
                    handleSendOtp={handleSendOtp}
                    handleVerifyOtp={handleVerifyOtp}
                    values={values}
                    setFieldValue={setFieldValue}
                    isPhoneVerified={isPhoneVerified}
                  />

                  <FormField
                    name="pan"
                    label="Pan Number"
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <FormField
                    name="dateOfBirth"
                    label="Date of Birth"
                    type="date"
                    isEditMode={isEditMode}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <FormField
                    name="city"
                    label="City"
                    isEditMode={isEditMode}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <FormField
                    name="district"
                    label="District"
                    isEditMode={isEditMode}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <FormField
                    name="state"
                    label="State"
                    isEditMode={isEditMode}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </Box>
              </Box>
              <Box sx={{ textAlign: "center", mt: 2 }}>
                {isEditMode ? (
                  <Button
                    type="submit"
                    disabled={isSubmitting || !isEditMode}
                    sx={{
                      width: "fit-content",
                      minWidth: "100px",
                      borderRadius: "15px",
                      padding: "2px 30px",
                      height: "40px",
                      background: "linear-gradient(90deg, #625DBD 0%, #2B4257 100%)",
                      color: "white",
                    }}
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                ) : (
                  <Button
                    onClick={handleEditClick}
                    type="button"
                    sx={{
                      width: "fit-content",
                      minWidth: "100px",
                      borderRadius: "15px",
                      padding: "2px 30px",
                      height: "40px",
                      background: "linear-gradient(90deg, #2B4257 0%, #625DBD 100%)",
                      color: "white",
                    }}
                  >
                    Edit
                  </Button>
                )}
              </Box>

            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};


export default EmployeeProfile;

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isEditEmailMode: PropTypes.bool,
  isEditNumberMode: PropTypes.bool,
  setIsEditEmailMode: PropTypes.func,
  setIsEditNumberMode: PropTypes.func,
  phoneOtpSent: PropTypes.bool,
  emailOtpSent: PropTypes.bool,
  phoneOtpTimer: PropTypes.number,
  emailOtpTimer: PropTypes.number,
  sendOtpLoading: PropTypes.bool,
  verifyOtpLoading: PropTypes.bool,
  handleSendOtp: PropTypes.func,
  handleVerifyOtp: PropTypes.func,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isEmailVerified: PropTypes.bool,
  isPhoneVerified: PropTypes.bool
};