import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, CssBaseline, Toolbar, Typography, IconButton, Badge, Popover, 
  AppBar as MuiAppBar, Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, Button, Menu, MenuItem
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import clearbiz3 from "../../../assets/clearbiz3.png";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { clearLoginUserData } from "../../../store/actions";
import { useDispatch } from 'react-redux';
import { dispatchAll } from "../../../utils";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
  [theme.breakpoints.down('sm')]: {
    height: 56,
  },
}));

function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClickLogout = () => {
    dispatchAll([clearLoginUserData], dispatch);
    localStorage.clear();
    navigate("/");
  };

  const handleProfileNavigation = () => {
    handleProfileClose();
    navigate('/employee/profile');
  };

  const handleLogoClick = () => {
    navigate('/employee/dashboard');
  };
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ bgcolor: '#fff' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            <button
              onClick={handleLogoClick}
              style={{
                cursor: 'pointer',
                background: 'none',
                border: 'none',
                padding: 0,
              }}
            >
              <img
                src={clearbiz3}
                className="clearbiz-img"
                alt="Clearbiz Logo"
                style={{
                  width: '180px',
                  height: 'auto',
                  marginBottom: '8%',
                  marginRight: '2%',
                }}
              />
            </button>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton
              size="large"
              color="inherit"
              onClick={handleNotificationClick}
            >
              <Badge badgeContent={notifications.length || 0} color="error">
                <NotificationsIcon style={{ color: '#2b4257', fontSize: '1.8rem' }} />
              </Badge>
            </IconButton>
            
            <IconButton
              onClick={handleProfileClick}
              size="large"
              color="inherit"
            >
              <MenuIcon style={{ color: '#2b4257', fontSize: '1.8rem' }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={profileAnchorEl}
        open={Boolean(profileAnchorEl)}
        onClose={handleProfileClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleProfileNavigation}>
                <IconButton size="large" color="inherit">
                  <AccountCircleIcon />
                </IconButton>
                <p style={{ marginBottom: '1px' }}>Profile</p>
              </MenuItem>
              <MenuItem onClick={()=>{
                handleProfileClose();
                setOpenDialog(true);
              }}>
                <IconButton size="large" color="inherit">
                  <ExitToAppIcon />
                </IconButton>
                <p style={{ marginBottom: '1px' }}>Sign out</p>
              </MenuItem>
      </Menu>

      <Popover
        open={Boolean(notificationAnchorEl)}
        anchorEl={notificationAnchorEl}
        onClose={() => setNotificationAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{
          width: 300,
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 100,
        }}>
          <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center', fontWeight: 500 }}>
            No notifications to display.
          </Typography>
        </Box>
      </Popover>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{ '& .MuiDialog-paper': { width: '500px' } }}
      >
        <DialogTitle>{'Confirm Sign Out'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to exit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus color="primary">
            No
          </Button>
          <Button onClick={onClickLogout} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}export default Sidebar;
