import sliceCreator from '../index.js';
import {TESTIMONIALS} from "../../../constants/index.js"

const _getTestimonialsAction = sliceCreator('TESTIMONIALS', TESTIMONIALS, 'get');

const { asyncAction: getTestimonialsAction, reducer, clearData: clearTestimonialsData } = _getTestimonialsAction;

export default reducer;

export {
    getTestimonialsAction,
    clearTestimonialsData
}

