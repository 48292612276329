import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, IconButton, Grid,useMediaQuery,useTheme } from '@mui/material';
import TrustPartner from '../../assets/TrustPartner.png';
import PartnerDashboard from '../../assets/PartnerDashboard.png';
import CurrentOutstanding from '../../assets/CurrentOutstanding.png';
import Disputes from '../../assets/Disputes.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const KeyFeatures = () => {
  const theme = useTheme();
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleFeatureClick = (feature) => {
    if (isMobile) {
      setExpanded(expanded === feature ? null : feature); 
    } else {
      setSelectedFeature(expanded === feature ? null : feature); 
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.feature-box')) {
        setSelectedFeature(null);
      }
    };
    if (selectedFeature) {
      document.addEventListener('click', handleClickOutside);
    }
        return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectedFeature]);

  useEffect(() => {
    if (selectedFeature && isMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [selectedFeature, isMobile]);


  return (
    <Paper elevation={0} sx={{marginTop:{sm:'10%',md:'5%',lg:'7%'}}}>
      <Grid container>
        <Grid item sx={{ display: { xs: 'none', sm: 'flex' }}} container>
          <Grid item sm={6} >
            <Typography
              sx={{
                fontFamily: 'Ruwudu',
                fontSize: { xs: '1.5rem', sm: '2rem' },
                width:'80%',
                color: '#000',
                marginLeft: '15%',
                marginBottom:'15%',
              }}
            >
              Key Features To Empower Your Business
            </Typography>
            <Box sx={{ marginLeft: { xs: '5%', sm: '15%' } }}>
              <Typography 
                sx={{ fontFamily: 'Nirmala UI', color: '#000', marginTop: '5%', '&:hover': { cursor: 'pointer' } }}
                onMouseEnter={() => handleFeatureClick('dashboard')}
                onMouseLeave={() => setSelectedFeature(null)}

              >
                01 Partner Dashboard : Stay Updated at a Glance
              </Typography>
              <hr style={{backgroundColor: '#000', width: '70%' }} />
              <Typography
                sx={{ fontFamily: 'Nirmala UI', color: '#000', marginTop: '5%', '&:hover': { cursor: 'pointer' } }}
                onMouseEnter={() => handleFeatureClick('outstanding')}
                onMouseLeave={() => setSelectedFeature(null)}
              >
                02 Current Outstanding & Critical Due Reports
              </Typography>
              <hr style={{ backgroundColor: '#000', width: '70%' }} />
              <Typography
                sx={{ fontFamily: 'Nirmala UI', color: '#000', marginTop: '5%', '&:hover': { cursor: 'pointer' } }}
                onMouseEnter={() => handleFeatureClick('disputes')}
                onMouseLeave={() => setSelectedFeature(null)}
>
                03 Disputes & Resolution Tracking
              </Typography>
              <hr style={{backgroundColor: '#000', width: '70%' }} />
              <Typography
                sx={{ fontFamily: 'Nirmala UI', color: '#000', marginTop: '5%', '&:hover': { cursor: 'pointer' } }}
                onMouseEnter={() => handleFeatureClick('analysis')}
                onMouseLeave={() => setSelectedFeature(null)}
>
                04 In-depth Partner Analysis
              </Typography>
              <hr style={{  backgroundColor: '#000', width: '70%' }} />
            </Box>
          </Grid>

          <Grid item sm={6} sx={{ position: 'relative' }}>
            <Typography sx={{ color: '#000', marginLeft: '20%' ,fontFamily:'Ruwudu',pt:2}}>
              ClearBiz is a user-friendly app that allows businesses to rate and review their partners based on payment
              history and reliability.
            </Typography>
            <img 
            srcSet={`${TrustPartner} 600w, ${TrustPartner} 1200w`}
            sizes="(max-width: 600px) 100vw, 50vw"
            src={TrustPartner} alt='keyfeatures' style={{ width: '50%', height: 'auto', marginLeft: '20%',marginTop:'15%',objectFit:'contain' }} />
            {selectedFeature && (
              <Box className='feature-box'
                sx={{
                  position: 'absolute',
                  top: 0,
                  width: '97%',
                  height: {sm:'75%',md:'65%'},
                  marginTop:{sm:'33%',md:'25%'},
                  backgroundColor: 'rgba(43, 66, 87, 1)',
                  color: '#fff',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px',
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {selectedFeature === 'dashboard' && (
                  <>
                    <Typography variant="h5" sx={{ mb: 2, fontFamily: 'Ruwudu', fontWeight: 'bold' }}>
                      Partner Dashboard : Stay Updated at a Glance
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', color: '#fff', fontFamily: 'Ruwudu' }}>
                      Get a comprehensive overview of your linked partners with real-time updates. See their average ratings, monitor their activity, and be instantly informed of any changes or updates that may impact your business relationships.
                    </Typography>
                    <img
                      srcSet={`${PartnerDashboard} 600w, ${PartnerDashboard} 1200w`}
                      sizes="(max-width: 600px) 100vw, 50vw"
                      src={PartnerDashboard}
                      alt="Dashboard Preview"
                      width='400'
                      height='300'
                      style={{ width: '35%', marginLeft: '65%',marginTop:'auto' ,objectFit:'contain',aspectRatio:'auto'}}
                    />
                  </>
                )}

                {selectedFeature === 'outstanding' && (
                  <>
                    <Typography variant="h5" sx={{ mb: 2, fontFamily: 'Ruwudu', fontWeight: 'bold' }}>
                      Current Outstanding & Critical Due Reports
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', color: '#fff', fontFamily: 'Ruwudu' }}>
                      Stay on top of your receivables with instant visibility into outstanding amounts and critical dues. ClearBiz highlights key financial information so you can prioritize follow-ups and manage cash flow efficiently.
                    </Typography>
                    <img
                      srcSet={`${CurrentOutstanding} 600w, ${CurrentOutstanding} 1200w`}
                      sizes="(max-width: 600px) 100vw, 50vw" 
                      src={CurrentOutstanding}
                      alt="Outstanding Reports"
                      width='400'
                      height='300'
                      style={{ width: '35%', marginLeft: '65%',marginTop:'auto',objectFit:'contain',aspectRatio:'auto' }}
                    />
                  </>
                )}

                {selectedFeature === 'disputes' && (
                  <>
                    <Typography variant="h5" sx={{ mb: 2, fontFamily: 'Ruwudu', fontWeight: 'bold' }}>
                      Disputes & Resolution Tracking
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', color: '#fff', fontFamily: 'Ruwudu' }}>
                      Track and manage disputes seamlessly. Our platform provides clear insights into unresolved issues with partners, enabling you to stay informed and ensure timely dispute resolution.
                    </Typography>
                    <img
                      srcSet={`${Disputes} 600w, ${Disputes} 1200w`}
                      sizes="(max-width: 600px) 100vw, 50vw"
                      src={Disputes}
                      alt="Dispute Tracking"
                      width='400'
                      height='300'
                      style={{ width: '35%', marginLeft: '65%',marginTop:'auto',objectFit:'contain',aspectRatio:'auto' }}
                    />
                  </>
                )}

                {selectedFeature === 'analysis' && (
                  <>
                    <Typography variant="h5" sx={{ mb: 2, fontFamily: 'Ruwudu', fontWeight: 'bold' }}>
                      In-depth Partner Analysis
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', color: '#fff', fontFamily: 'Ruwudu' }}>
                      Make data-driven decisions with detailed analysis on selected partners. ClearBiz provides insights on recovery probability, partner reachability, capital standing, and asset worthiness, ensuring you make informed decisions before extending credit or finalizing deals.
                    </Typography>
                    <img
                      srcSet={`${PartnerDashboard} 600w, ${PartnerDashboard} 1200w`}
                      sizes="(max-width: 600px) 100vw, 50vw"
                      src={PartnerDashboard}
                      alt="Partner Analysis"
                      width='400'
                      height='300'
                      style={{ width: '35%', marginLeft: '65%',marginTop:'auto',objectFit:'contain',aspectRatio:'auto' }}
                    />
                  </>
                )}
              </Box>
            )}
          </Grid>
        </Grid>

        
        {isMobile && (
          <Grid item xs={12} sx={{ p: { xs: 2, sm: 0 } }}>
            <Typography
              sx={{
                fontFamily: 'Ruwudu',
                fontSize: { xs: '1.5rem', sm: '2rem' },
                textAlign: 'center',
                color: '#000',
                mb: { xs: 2, sm: 0 },
              }}
            >
              Key Features To Empower Your Business
              </Typography>
            <Box sx={{ display: { xs: 'block', sm: 'none' }, width: '100%' }}>
              {[
                { text: 'Partner Dashboard: Stay Updated at a Glance', feature: 'dashboard' },
                { text: 'Current Outstanding & Critical Due Reports', feature: 'outstanding' },
                { text: 'Disputes & Resolution Tracking', feature: 'disputes' },
                { text: 'In-depth Partner Analysis', feature: 'analysis' },
              ].map((item) => (
                <Box
                  key={item.feature}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p:1,
                    borderBottom: '1px solid #ddd',
                    '&:hover': { cursor: 'pointer' },
                  }}
                >
                  <Typography
                    sx={{ fontFamily: 'Nirmala UI', color: '#000', fontSize: '0.8rem' }}
                  >
                    {item.text}
                    <IconButton onClick={() => handleFeatureClick(item.feature)}>
                    {expanded === item.feature ? (
                      <ArrowDownwardIcon sx={{ color: '#000' }} />
                    ) : (
                      <ArrowForwardIcon sx={{ color: '#000' }} />
                    )}
                  </IconButton>
                  </Typography>
                  {expanded === item.feature && (
                    <Box sx={{ padding: '10px 0' }}>
                      {item.feature === 'dashboard' && (
                        <Typography sx={{ fontSize: '0.75rem', color: '#000', fontFamily: 'Ruwudu' }}>
                          Get a comprehensive overview of your linked partners with real-time updates. See their average ratings, monitor their activity, and be instantly informed of any changes or updates that may impact your business relationships.
                        </Typography>
                      )}
                      {item.feature === 'outstanding' && (
                        <Typography sx={{ fontSize: '0.75rem', color: '#000', fontFamily: 'Ruwudu' }}>
                          Stay on top of your receivables with instant visibility into outstanding amounts and critical dues. ClearBiz highlights key financial information so you can prioritize follow-ups and manage cash flow efficiently.
                        </Typography>
                      )}
                      {item.feature === 'disputes' && (
                        <Typography sx={{ fontSize: '0.75rem', color: '#000', fontFamily: 'Ruwudu' }}>
                          Track and manage disputes seamlessly. Our platform provides clear insights into unresolved issues with partners, enabling you to stay informed and ensure timely dispute resolution.
                        </Typography>
                      )}
                      {item.feature === 'analysis' && (
                        <Typography sx={{ fontSize: '0.75rem', color: '#000', fontFamily: 'Ruwudu' }}>
                          Make data-driven decisions with detailed analysis on selected partners. ClearBiz provides insights on recovery probability, partner reachability, capital standing, and asset worthiness, ensuring you make informed decisions before extending credit or finalizing deals.
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>  
              ))}               <Typography
              sx={{
                color: '#000',
                textAlign: 'center',
                fontSize: { xs: '0.9rem', sm: '1rem' },
                mb: { xs: 3, sm: 0 },
                mt:5,
              }}
            >
              Clear Biz is a user-friendly app that allows businesses to rate and review their partners based on payment
              history and reliability.
            </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default KeyFeatures;
