import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Grid, Typography, Box, Button } from "@mui/material";
import Sidebar from "../../../components/sidebar";
import {
  cleareditEmployeeRatingAction,
  registrationAction,
  postProfileUploadAction,
} from "../../../store/actions";
import "./styles.css";
import { toast } from "react-toastify";
import Select from "react-select";
import { getStates, getDistricts } from "../../../utils";
import PropTypes from 'prop-types';

const textFieldStyle = {
  "& .MuiInputBase-root": {
    borderRadius: "15px",
  },
  "& .MuiFilledInput-root": {
    border: "2px solid transparent",
    borderRadius: "4px",
    backgroundColor: "linear-gradient(90deg, #0057ED 0%, #2C5172 100%)",
    transition: "border 0.3s ease",
  },
  "& .MuiFilledInput-root.Mui-focused": {
    border: "2px solid transparent",
    borderImage: "linear-gradient(90deg, #0057ED 0%, #2C5172 100%)",
    borderImageSlice: 1,
  },
};

const selectStyles = {
  control: (base) => ({
    ...base,
    height: '40px',
    borderRadius: "15px",
    background: "white",
    border: "2px solid #0057ED",
    boxShadow: "none",
    "&:hover": {
      border: "2px solid #0057ED"
    },
    "&:focus": {
      border: "2px solid #0057ED"
    }
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 8px'
  }),
  input: (base) => ({
    ...base,
    margin: '0'
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "#0057ED" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "#e6f0ff"
    }
  })
};

function NewEmployee() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successData, error, errorInfo } =
    useSelector((state) => state.registrationData) || {};
  const [fileName, setFileName] = useState("No file chosen");
  const [profilePicture, setProfilePicture] = useState(null);
  const userDetail = JSON.parse(localStorage.getItem("UserDetail") || "{}");
  const userId = userDetail.id;
  const [statesList, setStatesList] = useState([]);
  const [districtsList, setDistrictsList] = useState([]);

  const validationSchema = Yup.object({
    fullname: Yup.string().required("Full name is required"),
    fatherName: Yup.string().required("Father name is required"),
    date: Yup.date().required("Date of birth is required"),
    pan: Yup.string()
      .matches(/^[A-Z]{5}\d{4}[A-Z]$/, 'Invalid PAN number')
      .required("PAN number is required"),
    confirmpan: Yup.string()
      .transform((value) => value?.toUpperCase())
      .oneOf([Yup.ref("pan"), null], "PAN numbers must match")
      .required("Confirm PAN number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .matches(/^\d{10}$/, "Invalid mobile number")
      .required("Mobile number is required"),
    state: Yup.string().required("State is required"),
    district: Yup.string().required("District is required"),
    city: Yup.string().required("City is required"),
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        fullname: "",
        fatherName: "",
        date: "",
        pan: "",
        confirmpan: "",
        email: "",
        mobileNumber: "",
        state: "",
        district: "",
        city: "",
        image: null,
      },
      validationSchema,
      onSubmit: (values) => {
        const formattedDate = new Date(values.date).toISOString().split("T")[0];
        const payload = {
          user: {
            first_name: values.fullname,
            father_name: values.fatherName,
            date_of_birth: formattedDate,
            pan_number: values.pan,
            email: values.email,
            phone_number: values.mobileNumber,
            is_employee: true,
            created_by: userId,
            role: "employee",
            user_type: 13,
            state: values.state,
            district: values.district,
            city: values.city,
            image: profilePicture,
          },
        };

        dispatch(registrationAction(payload));
      },
    });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file type
      if (!file.type.startsWith('image/')) {
        toast.error('Please upload an image file');
        return;
      }
      // Validate file size (2MB limit)
      if (file.size > 2 * 1024 * 1024) {
        toast.error('Image size should be less than 2MB');
        return;
      }
      setProfilePicture(file);
      setFileName(file.name);
    } else {
      setFileName("No file chosen");
    }
  };

  const handleProfilePictureUpload = () => {
    if (!profilePicture) {
      return;
    }
    const formData = new FormData();
    formData.append("profile_picture", profilePicture);
    formData.append("user_id", successData?.data?.user.id);
    dispatch(postProfileUploadAction(formData));
  };

  const FormFieldError = ({ fieldName }) =>
    errors[fieldName] &&
    touched[fieldName] && (
      <Typography variant="body2" color="error" ml={{ xs: "2%", sm: "4%" }}>
        {errors[fieldName]}
      </Typography>
    );
  FormFieldError.propTypes = {
    fieldName: PropTypes.string.isRequired,
  };

  useEffect(() => {
    if (successData) {
      toast.success("Employee Created Successfully");
      handleProfilePictureUpload();
      dispatch(cleareditEmployeeRatingAction());
      navigate("/employeeEdit", { state: { Id: successData.data.user.id } });
    }
  }, [successData, navigate, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(errorInfo);
    }
  }, [error, errorInfo]);

  useEffect(() => {
    setStatesList(getStates());
  }, []);

  return (
    <>
      <Sidebar />
      <Box
        sx={{
          flexGrow: 1,
          width: "80%",
          marginLeft: { xs: "8%", sm: "20%" },
          marginBottom: "5%",
          marginTop: { xs: "20%", sm: "7%" },
        }}
      >
        <Grid container>
          <Grid item>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "1.5rem", sm: "2.125rem" },
                textAlign: "center",
                background: "linear-gradient(90deg, #2B4257 0%, #625DBD 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginRight: { xs: "0%", sm: "8%" },
                ml: { xs: "5%", sm: "0%" },
              }}
            >
              New Employee Details
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: "2%",
                textAlign: "center",
                background: "linear-gradient(90deg, #0057ED 0%, #2C5172 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginRight: { xs: "0%", sm: "12%" },
                ml: { xs: "5%", sm: "0%" },
              }}
            >
              Employee Details
            </Typography>

            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                p={{ xs: 2, md: 4 }}
                sx={{ width: "100%", maxWidth: "1200px", margin: "0 auto" }}
              >
                <Grid
                  item
                  container
                  spacing={2}
                  gap={{ xs: 0, sm: 4 }}
                  sx={{
                    flexDirection: { xs: "none", sm: "row", md: "none" },
                    flexWrap: { sm: "nowrap" },
                  }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography ml={{ xs: "2%", sm: "4%" }} color="#000">
                      Full Name
                    </Typography>
                    <TextField
                      name="fullname"
                      value={values.fullname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      size="small"
                      focused
                      sx={textFieldStyle}
                    />
                    <FormFieldError fieldName="fullname" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography ml={{ xs: "2%", sm: "4%" }} color="#000">
                      Father Name
                    </Typography>
                    <TextField
                      name="fatherName"
                      value={values.fatherName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      size="small"
                      focused
                      sx={textFieldStyle}
                    />
                    <FormFieldError fieldName="fatherName" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography ml={{ xs: "2%", sm: "4%" }} color="#000">
                      Date of Birth
                    </Typography>
                    <TextField
                      type="date"
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      size="small"
                      focused
                      sx={textFieldStyle}
                    />
                    <FormFieldError fieldName="date" />
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  spacing={2}
                  gap={{ xs: 0, sm: 4 }}
                  sx={{
                    flexDirection: { xs: "none", sm: "row", md: "none" },
                    flexWrap: { sm: "nowrap" },
                  }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography ml={{ xs: "2%", sm: "4%" }} color="#000">
                      PAN Number
                    </Typography>
                    <TextField
                      name="pan"
                      value={values.pan}
                      onChange={(e) => {
                        const upperValue = e.target.value.toUpperCase();
                        handleChange({
                          target: {
                            name: 'pan',
                            value: upperValue
                          }
                        });
                      }}
                      onBlur={handleBlur}
                      fullWidth
                      size="small"
                      focused
                      inputProps={{
                        maxLength: 10,
                      }}
                      sx={textFieldStyle}
                    />
                    <FormFieldError fieldName="pan" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography ml={{ xs: "2%", sm: "4%" }} color="#000">
                      Confirm PAN Number
                    </Typography>
                    <TextField
                      name="confirmpan"
                      value={values.confirmpan}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      size="small"
                      focused
                      sx={textFieldStyle}
                    />
                    <FormFieldError fieldName="confirmpan" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography ml={{ xs: "2%", sm: "4%" }} color="#000">
                      Email ID
                    </Typography>
                    <TextField
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      size="small"
                      focused
                      sx={textFieldStyle}
                    />
                    <FormFieldError fieldName="email" />
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  spacing={2}
                  gap={{ xs: 0, sm: 4 }}
                  sx={{
                    flexDirection: { xs: "none", sm: "row", md: "none" },
                    flexWrap: { sm: "nowrap" },
                  }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography ml={{ xs: "2%", sm: "4%" }} color="#000">
                      Mobile Number
                    </Typography>
                    <TextField
                      name="mobileNumber"
                      value={values.mobileNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      size="small"
                      focused
                      inputProps={{
                        maxLength: 10,
                        pattern: "[0-9]*",
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, '')
                      }}
                      sx={textFieldStyle}
                    />
                    <FormFieldError fieldName="mobileNumber" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid ml={{ xs: 3, sm: 5 }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#fff",
                    p: "0.2% 0.3%",
                    textAlign: "center",
                    width: { xs: "30%", sm: "15%" },
                    background:
                      "linear-gradient(90deg, #553EBD 0%, #7A538E 100%)",
                  }}
                >
                  Image
                </Typography>
                <Box
                  sx={{
                    width: { xs: "90%", sm: "45%", md: "35%" },
                    height: "50%",
                    padding: "2%",
                    mt: { xs: "5%", sm: "2%" },
                    display: "flex",
                    flexDirection: "row",
                    background: " rgba(248, 252, 255, 1)",
                    border: "1px dashed #553EBD",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      border: "0.52px solid",
                      borderImageSource:
                        "linear-gradient(90deg, #553EBD 0%, #7A538E 100%)",
                      borderImageSlice: 1,
                      backgroundColor: "transparent",
                      color: "#553EBD",
                      "&:hover": {
                        backgroundColor: "rgba(85, 62, 189, 0.1)",
                      },
                    }}
                  >
                    Choose File <input type="file" hidden onChange={handleFileChange} />
                  </Button>
                  <Typography variant="body2" ml={2} mt={1}>
                    {fileName}
                  </Typography>
                </Box>
              </Grid>

              <Grid
                container
                spacing={2}
                p={{ xs: 2, md: 4 }}
                sx={{ width: "100%", maxWidth: "1200px", margin: "0 auto" }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    textAlign: "center",
                    width: "100%",
                    background:
                      "linear-gradient(90deg, #0057ED 0%, #2C5172 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    mr: "10%",
                  }}
                >
                  Address
                </Typography>

                <Grid
                  container
                  spacing={2}
                  mt={0}
                  gap={{ xs: 0, sm: 4 }}
                  sx={{
                    flexDirection: { xs: "none", sm: "row", md: "none" },
                    flexWrap: { sm: "nowrap" },
                  }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography ml={{ xs: "2%", sm: "4%" }} color="#000">
                      State
                    </Typography>
                    <Select
                      name="state"
                      value={statesList.find(
                        (option) => option.value === values.state
                      )}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: "state",
                            value: selectedOption.value,
                          },
                        });
                        setDistrictsList(getDistricts(selectedOption.value));
                      }}
                      options={statesList}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={selectStyles}
                    />

                    <FormFieldError fieldName="state" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography ml={{ xs: "2%", sm: "4%" }} color="#000">
                      District
                    </Typography>
                    <Select
                      name="district"
                      value={districtsList.find(
                        (option) => option.value === values.district
                      )}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: "district",
                            value: selectedOption.value,
                          },
                        });
                      }}
                      options={districtsList}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={selectStyles}
                    />

                    <FormFieldError fieldName="district" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography ml={{ xs: "2%", sm: "4%" }} color="#000">
                      City
                    </Typography>
                    <TextField
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      size="small"
                      focused
                      sx={textFieldStyle}
                    />
                    <FormFieldError fieldName="city" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={2} mb={"2%"} justifyContent="center">
                <Grid item xs={10} sm={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      background:
                        "linear-gradient(90deg, #553EBD 0%, #7A538E 100%)",
                      width: { xs: "100%", sm: "75%", md: "50%" },
                      mx: "auto",
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default NewEmployee;
