import React, { useState } from 'react';
import { Box, CircularProgress, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button,Grid, TextField } from '@mui/material';
import Table from '../../../components/table';
import TableData from '../../../utils/tableutils';
import { useDispatch } from 'react-redux';
import { deleteEditReceivedRatingAction,editReceivedRatingAction,getUserSearchAction } from "../../../store/actions";
import PropTypes from 'prop-types';
import DropdownItems from "../../../utils/dropdownutils";
import Select from "react-select";
import { toast } from 'react-toastify';
import { canEnableField } from '../../../utils';

const ReceivedRatingTable = ({ receivedRatings, searchValue, loading }) => {
  const columns = React.useMemo(() => TableData.recievedRatings, []);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newScore, setNewScore] = useState('');
  const dispatch = useDispatch();

  const deleteHandler = (row) => {
    setSelectedUser(row);
    setOpenDeleteDialog(true);
  };

    const [values, setValues] = useState({
      totaloutstanding: '',
      dso: '',
      pendingrecievables: '',
      since: '',
      legal: '',
      reachability: '',
      recoveryprobability: '',
      ontimepayment: '',
      comments: '',
    });

    const errors = {}; // Replace with actual error handling logic
    const touched = {}; // Replace with actual touched state logic

    const handleChange = (event) => {
      const { name, value } = event.target;
      setValues({ ...values, [name]: value });
    };
  
    const handleBlur = () => {
      // Implement blur logic if needed
    };
  
    const setFieldValue = (field, value) => {
      setValues({ ...values, [field]: value });
    };
  

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedUser(null);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedUser(null);
  };

  const handleRecievedRatingView = (row) => {
    setSelectedUser(row);
    setNewScore(row.score); // Assuming the row has a score property
    const knownSinceDate = row.known_since ? row.known_since.split('-').slice(0, 2).join('-') : '';
    setValues({
      totaloutstanding: DropdownItems.totaloutstanding.find(option => option.value === row.total_outstanding) || '',
      dso: DropdownItems.dso.find(option => option.value === row.dso) || '',
      pendingrecievables: DropdownItems.pendingrecievable.find(option => option.value === row.pending_receivables) || '',
      since: knownSinceDate,
      legal: DropdownItems.legal.filter((v) => v.value === Number(row.legal_proceedings))[0],
      reachability: DropdownItems.reachability.find(option => option.value === row.reachability) || '',
      recoveryprobability: DropdownItems.recoveryprobability.find(option => option.value === row.recovery_probability) || '',
      ontimepayment: DropdownItems.percentages.find(option => option.value === row.transaction_paid_ontime) || '',
      comments: row.comments || '',
    });
    setOpenEditDialog(true);
  };

  const handleConfirmDelete = () => {
    if (selectedUser) {
      dispatch(deleteEditReceivedRatingAction({ endPoint: `admin/edit-received-rating/${selectedUser.id}/` }));
      handleCloseDeleteDialog();
      dispatch(getUserSearchAction({
        endPoint: `admin/userSearch/?pan_number=${searchValue}`,
    }))
    toast.success("Rating Deleted Sucessfully")
    }
  };

  const handleSaveScore = () => {
    if (selectedUser) {
      dispatch(editReceivedRatingAction({ endPoint: `admin/edit-received-rating/${selectedUser.id}/`, 
        score: newScore,
        total_outstanding: values.totaloutstanding.value,
        dso: values.dso.value,
        pending_receivables: values.pendingrecievables.value,
        known_since: values.since ? `${values.since}-01` : null,
        legal_proceedings: values.legal.value,
        reachability: values.reachability.value,
        recovery_probability: values.recoveryprobability.value,
        on_time_Payment: values.ontimepayment.value,
        comments: values.comments, 
      }));
      handleCloseEditDialog();
      dispatch(getUserSearchAction({
        endPoint: `admin/userSearch/?pan_number=${searchValue}`,
    }))
    toast.success("Score Updated Sucessfully")
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (!receivedRatings || receivedRatings.length === 0) {
    return (
      <Box mt={2}>
        <Typography>No received ratings available</Typography>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Typography variant="h6">
          Total ReceivedRatings: {receivedRatings.length}
        </Typography>
      </Box>
      <Table columns={columns} data={receivedRatings} handleRatingDeleteClick={deleteHandler} handleRecievedRatingView={handleRecievedRatingView} />

      {/* Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this rating?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Score Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} maxWidth="sm" fullWidth 
        PaperProps={{
          sx: {
            width: '80%',
            maxWidth: '500px',
            height: '400px',
            minHeight: '70vh',
          },
        }} >
        <DialogTitle>Edit Score</DialogTitle>
        <DialogContent
          sx={{
            //overflow: 'hidden',
            padding: 2, 
          }}
        >
          <Box display="flex" justifyContent="center">
            <Select
              defaultValue={DropdownItems.score.find(option => option.value === Number(newScore)) || null}
              value={DropdownItems.score.find(option => option.value === Number(newScore)) || null}
              onChange={(selectedOption) => setNewScore(selectedOption ? selectedOption.value : '')}
              name="score"
              id="score"
              options={DropdownItems.score}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: '350px',
                  margin: 'auto',
                  borderColor: 'black',
                }),
              }}
            />
          </Box>

          <Grid container spacing={2}>
            {/* Current Outstanding */}
            <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField({ value: newScore }, "totaloutstanding") ? "block" : "none", justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start", ml: { xs: -2.5, sm: 0 }, }}>
              <Typography color='#000'>Current Outstanding</Typography>
              <Select
                value={values.totaloutstanding}
                name="totaloutstanding"
                id="totaloutstanding"
                onChange={(selectedOption) => setFieldValue("totaloutstanding", selectedOption)}
                options={DropdownItems.totaloutstanding}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    maxWidth: "300px",
                    borderColor: "black",
                  }),
                }}
              />
              {errors.totaloutstanding && touched.totaloutstanding && (
                <Typography color="error">{errors.totaloutstanding?.value || errors.totaloutstanding}</Typography>
              )}
            </Grid>

            {/* Days Since Outstanding */}
            <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField({ value: newScore }, "dso") ? "block" : "none", justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start", ml: { xs: -2.5, sm: 0 }, }}>
              <Typography color='#000'>Days Since Outstanding</Typography>
              <Select
                value={values.dso}
                name="dso"
                id="dso"
                onChange={(selectedOption) => setFieldValue("dso", selectedOption)}
                options={DropdownItems.dso}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    maxWidth: "300px",
                    borderColor: "black",
                  }),
                }}
              />
              {errors.dso && touched.dso && (
                <Typography color="error">{errors.dso?.value || errors.dso}</Typography>
              )}
            </Grid>

            {/* Pending Receivable */}
            <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField({ value: newScore }, "pendingrecievable") ? "block" : "none", justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start", ml: { xs: -2.5, sm: 0 }, }}>
              <Typography color='#000'>Pending Receivable</Typography>
              <Select
                value={values.pendingrecievables}
                name="pendingrecievable"
                id="pendingrecievable"
                onChange={(selectedOption) => setFieldValue("pendingrecievables", selectedOption)}
                options={DropdownItems.pendingrecievable}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    maxWidth: "300px",
                    borderColor: 'black'
                  }),
                }}
              />
              {errors.pendingrecievables && touched.pendingrecievables && (
                <Typography color="error">{errors.pendingrecievables?.value || errors.pendingrecievables}</Typography>
              )}
            </Grid>

            {/* Pending Since */}
            <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField({ value: newScore }, "since") ? "block" : "none", justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start", ml: { xs: -2.5, sm: 0 }, }}>
              <Typography color='#000'>Pending Since</Typography>
              <TextField
                fullWidth
                type="month"
                name="since"
                id="since"
                value={values.since}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  inputProps: {
                    max: new Date().toISOString().split("-").slice(0, 2).join("-"),
                  },
                  style: { height: '38px' },
                }}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: 'black',
                  })
                }}
                error={Boolean(errors.since && touched.since)}
                helperText={errors.since && touched.since ? errors.since : ""}
              />
            </Grid>

            {/* Legal Proceedings */}
            <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField({ value: newScore }, "legal") ? "block" : "none", justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start", ml: { xs: -2.5, sm: 0 }, }}>
              <Typography color='#000'>Legal Proceedings</Typography>
              <Select
                value={values.legal}
                name="legal"
                id="legal"
                onChange={(selectedOption) => setFieldValue("legal", selectedOption)}
                options={DropdownItems.legal}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    maxWidth: "300px",
                    borderColor: "black",
                  }),
                }}
              />
              {errors.legal && touched.legal && (
                <Typography color="error">{errors.legal?.value || errors.legal}</Typography>
              )}
            </Grid>

            {/* Reachability */}
            <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField({ value: newScore }, "reachability") ? "block" : "none", justifyContent: 'center', ml: { xs: -2.5, sm: 0 }, }}>
              <Typography color='#000'>Reachability</Typography>
              <Select
                value={values.reachability}
                name="reachability"
                id="reachability"
                onChange={(selectedOption) => setFieldValue("reachability", selectedOption)}
                options={DropdownItems.reachability}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    maxWidth: "300px",
                    borderColor: "black",
                  }),
                }}
              />
              {errors.reachability && touched.reachability && (
                <Typography color="error">{errors.reachability?.value || errors.reachability}</Typography>
              )}
            </Grid>

            {/* Recovery Probability */}
            <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField({ value: newScore }, "recoveryprobability") ? "block" : "none", justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start", ml: { xs: -2.5, sm: 0 }, }}>
              <Typography color='#000'>Recovery Probability</Typography>
              <Select
                value={values.recoveryprobability}
                name="recoveryprobability"
                id="recoveryprobability"
                onChange={(selectedOption) => setFieldValue("recoveryprobability", selectedOption)}
                options={DropdownItems.recoveryprobability}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    maxWidth: "300px",
                    borderColor: "black",
                  }),
                }}
              />
              {errors.recoveryprobability && touched.recoveryprobability && (
                <Typography color="error">{errors.recoveryprobability?.value || errors.recoveryprobability}</Typography>
              )}
            </Grid>

            {/* On-time Payment */}
            <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField({ value: newScore }, "percentages") ? "block" : "none", justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start", ml: { xs: -2.5, sm: 0 }, }}>
              <Typography color='#000'>On-time Payment</Typography>
              <Select
                value={values.ontimepayment}
                name="ontimepayment"
                id="ontimepayment"
                onChange={(selectedOption) => setFieldValue("ontimepayment", selectedOption)}
                options={DropdownItems.percentages}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    maxWidth: "300px",
                    borderColor: "black",
                  }),
                }}
              />
              {errors.ontimepayment && touched.ontimepayment && (
                <Typography color="error">{errors.ontimepayment?.value || errors.ontimepayment}</Typography>
              )}
            </Grid>
          </Grid>

          {/* Comments */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', // Center the content horizontally
              justifyContent: values.score && values.score.value >= 9 ? 'center' : 'flex-start',
              margin: 'auto',
              width: '100%', // Ensure the Box takes full width of its container
            }}
          >
            <Typography
              component="label"
              htmlFor="comments"
              sx={{ marginTop: 2, fontSize: '1rem', fontWeight: 'bold' }}
            >
              Comments
            </Typography>
            <TextField
              type="text"
              name="comments"
              value={values.comments}
              onChange={handleChange}
              onBlur={handleBlur}
              id="comments"
              placeholder="Comments"
              multiline
              minRows={1}
              maxRows={6}
              sx={{
                width: '80%', // Set the desired width for the TextField
                maxWidth: '400px', // Set a maximum width for larger screens
                '& .MuiOutlinedInput-root': {
                  height: 'auto',
                },
              }}
              error={Boolean(errors.comments && touched.comments)}
              inputProps={{ maxLength: 300 }}
              helperText={
                errors.comments && touched.comments ? (
                  <Typography sx={{ color: 'red', fontSize: '0.875rem' }}>{errors.comments}</Typography>
                ) : null
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveScore} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ReceivedRatingTable.propTypes = {
  receivedRatings: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default ReceivedRatingTable;
