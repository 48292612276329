import React, { useState } from 'react';
import { Box, CircularProgress, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import Table from '../../../components/table';
import TableData from '../../../utils/tableutils';
import { useDispatch } from 'react-redux';
import { deleteEditReceivedRatingAction,editReceivedRatingAction,getUserSearchAction } from "../../../store/actions";
import PropTypes from 'prop-types';
import DropdownItems from "../../../utils/dropdownutils";
import Select from "react-select";
import { toast } from 'react-toastify';

const ReceivedRatingTable = ({ receivedRatings, searchValue, loading }) => {
  const columns = React.useMemo(() => TableData.recievedRatings, []);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newScore, setNewScore] = useState('');
  const dispatch = useDispatch();

  const deleteHandler = (row) => {
    setSelectedUser(row);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedUser(null);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedUser(null);
  };

  const handleRecievedRatingView = (row) => {
    setSelectedUser(row);
    setNewScore(row.score); // Assuming the row has a score property
    setOpenEditDialog(true);
  };

  const handleConfirmDelete = () => {
    if (selectedUser) {
      dispatch(deleteEditReceivedRatingAction({ endPoint: `admin/edit-received-rating/${selectedUser.id}/` }));
      handleCloseDeleteDialog();
      dispatch(getUserSearchAction({
        endPoint: `admin/userSearch/?pan_number=${searchValue}`,
    }))
    toast.success("Rating Deleted Sucessfully")
    }
  };

  const handleSaveScore = () => {
    if (selectedUser) {
      dispatch(editReceivedRatingAction({ endPoint: `admin/edit-received-rating/${selectedUser.id}/`, score: newScore }));
      handleCloseEditDialog();
      dispatch(getUserSearchAction({
        endPoint: `admin/userSearch/?pan_number=${searchValue}`,
    }))
    toast.success("Score Updated Sucessfully")
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (!receivedRatings || receivedRatings.length === 0) {
    return (
      <Box mt={2}>
        <Typography>No received ratings available</Typography>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Typography variant="h6">
          Total ReceivedRatings: {receivedRatings.length}
        </Typography>
      </Box>
      <Table columns={columns} data={receivedRatings} handleRatingDeleteClick={deleteHandler} handleRecievedRatingView={handleRecievedRatingView} />

      {/* Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this rating?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Score Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} maxWidth="sm" fullWidth 
        PaperProps={{
          sx: {
            width: '80%',
            maxWidth: '500px',
            height: '400px',
            minHeight: '70vh',
          },
        }} >
        <DialogTitle>Edit Score</DialogTitle>
        <DialogContent>
          <Select
            defaultValue={DropdownItems.score.find(option => option.value === Number(newScore)) || null}
            value={DropdownItems.score.find(option => option.value === Number(newScore)) || null}
            onChange={(selectedOption) => setNewScore(selectedOption ? selectedOption.value : '')}
            name="score"
            id="score"
            options={DropdownItems.score}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveScore} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ReceivedRatingTable.propTypes = {
  receivedRatings: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default ReceivedRatingTable;
