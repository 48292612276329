import sliceCreator from '../index.js';
import {PROFILE_UPLOAD} from "../../../constants/index.js"

const _postProfileUploadAction = sliceCreator('PROFILE_UPLOAD', PROFILE_UPLOAD, 'post');

const { asyncAction: postProfileUploadAction, reducer, clearData: clearPostProfileUploadAction } = _postProfileUploadAction;

export default reducer;

export {
    postProfileUploadAction,
    clearPostProfileUploadAction
}
