import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, styled } from '@mui/material';
import SideBar from "../../../components/sidebar";
import EmployeeListCard from './EmployeeListCard';
import { useSelector, useDispatch } from 'react-redux';
import { employeeDashboardAction } from '../../../store/actions';
import PropTypes from 'prop-types';


const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
  '& .MuiTab-root': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      minWidth: 'auto',
      padding: '6px 8px',
    },
    '@media (min-width: 600px) and (max-width: 750px)': {
      fontSize: '12px',
      padding: '8px 12px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      padding: '12px 16px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px',
      padding: '16px 24px',
    },
    '&.MuiTabs-indicator': {
      height: 3,
      borderRadius: '2px',
    }
  }
}));

function EmployeeDashboard() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const { loading, successData: employeeList } = useSelector((state) => state.getEmployeeDashboardData);
  const [verifySubTab, setVerifySubTab] = useState(0);

  useEffect(() => {
    dispatch(employeeDashboardAction());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleVerifySubTabChange = (event, newValue) => {
    setVerifySubTab(newValue);
  };

  const getActiveEmployees = () => {
    switch (activeTab) {
      case 0:
        return employeeList?.present_employees;
      case 1:
        return employeeList?.previous_employees;
      case 2:
        return verifySubTab === 0
          ? employeeList?.verification?.verification_pending_employees
          : employeeList?.verification?.transitional_employees;
      default:
        return [];
    }
  };

  return (
    <>
      <SideBar />
      <Box mt={{ xs: '20%', sm: '10%' }} mb={{ xs: '30%', sm: '15%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3, ml: { xs: '0%', sm: '15%', md: '0%' }, '@media (min-width: 600px) and (max-width:740px)': { ml: '25%' } }}>
          <StyledTabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Present Employees" />
            <Tab label="Previous Employees" />
            <Tab label="Verify Employees" />
          </StyledTabs>
        </Box>

        {activeTab === 2 && (
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <StyledTabs value={verifySubTab} onChange={handleVerifySubTabChange}>
              <Tab label="Pending Verification" />
              <Tab label="Transitional Employees" />
            </StyledTabs>
          </Box>
        )}

        <EmployeeListCard
          employees={getActiveEmployees()}
          isTransitional={activeTab === 2 && verifySubTab === 1}
          loading={loading}
        />
      </Box>
    </>
  );
}

export default EmployeeDashboard;

EmployeeListCard.propTypes = {
  employees: PropTypes.array
};
