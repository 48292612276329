import sliceCreator from '../index.js';
import {SEND_RATING_SMS} from "../../../constants"

const _sendRatingsmsAction = sliceCreator('SEND_RATING_SMS', SEND_RATING_SMS, 'post');

const { asyncAction: sendRatingsmsAction, reducer, clearData: clearSendRatingsmsAction } = _sendRatingsmsAction;

export default reducer;

export {
    sendRatingsmsAction,
    clearSendRatingsmsAction
}
