import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, Grid, Paper, Rating, Button, CircularProgress } from "@mui/material";
import SideBar from "../../../components/sidebar";
import EmployeeViewCard from "./EmployeeViewCard";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { employeeDetailsAction, getEmployeeRatingAction, globalConfigAction } from "../../../store/actions";
import EditIcon from '@mui/icons-material/Edit';
import VerifiedIcon from '@mui/icons-material/Verified';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import Loader from "../../../components/loader";

function EmployeeDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { successData: employeeDetails, loading: employeeLoading } = useSelector((state) => state.getEmployeeDetailsData);
  const { successData: globalSuccessData} = useSelector((state) => state.globalConfigData) || {};
  const { state } = useLocation();
  const [designationOptions, setDesignationOptions] = useState([]);

  const userDetailString = localStorage.getItem("UserDetail");
  const userDetail = userDetailString ? JSON.parse(userDetailString) : {};

  const businessId = userDetail.business.id;
  
  useEffect(() => {
    dispatch(employeeDetailsAction({
      endPoint: `employee-details/${state.Id}`,
    }));
  }, [dispatch , state.Id]);

  useEffect(() => {
    if (employeeDetails && !employeeDetails.current_experience) {
      setOpenDialog(true);
    }
  }, [employeeDetails]);

  const handleEdit = (Id) => {
    dispatch(getEmployeeRatingAction({
      endPoint: `employee-experience/${Id}/`,
    }));
    navigate('/employeeEdit', { state: { Id, employeeDetails } });
  };

  useEffect(() => {
    if (globalSuccessData) {
      setDesignationOptions(globalSuccessData.employee_designation);
    }
  }, [globalSuccessData]);
   
  useEffect(() => {
          dispatch(globalConfigAction());
      }, [dispatch]);

  const getDesignationName = (designationId, designationOptions) => {
    const designation = designationOptions.find(option => option.id === designationId);
    return designation ? designation.name : '';
  };
   return (
    <>
      <SideBar />

      <Box sx={{ position: 'absolute', top: 90, right: 30 }}>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{
            backgroundColor: '#82B2C4',
            '&:hover': {
              backgroundColor: '#6B99A9'
            }
          }}
        >
          Back
        </Button>
      </Box>

      {employeeLoading ? (
        <Loader className="loader"/>
      ) : (
        <Box sx={{ mt: 12 }}>
          {employeeDetails && (
            <EmployeeViewCard employeeDetails={employeeDetails} id={state.Id} />
          )}

          {employeeDetails?.previous_experiences?.length > 0 && (
            <Paper elevation={0} sx={{
              mt: '5%',
              width: '80%',
              pb: '5%',
              mb: '5%',
              pt: '2%',
              ml: { xs: '10%', sm: '20%', md: '16%' },
              boxShadow: '1px 0px 40px 0px rgba(0, 0, 0, 0.1)',
              background: 'hsla(0, 0%, 100%, 1)'
            }}>
              <Typography variant="h5" sx={{
                fontWeight: 'bold',
                marginBottom: 3,
                background: '#65A3DD',
                width: { xs: '100%', sm: '50%' },
                p: 1,
                color: 'white',
              }}>
                Previous Experiences
              </Typography>

              {/* Current Business Experience Cards */}
              <Grid container spacing={2} gap={4} mt={2} width={{ xs: '100%', sm: '100%', md: '100%' }}
                sx={{ justifyContent: 'center', px: 5 }}>
                {employeeDetails?.previous_experiences
                  .filter(review => businessId === review.organization)
                  .map((review, index) => (
                    <Grid item xs={22} sm={12} md={6} key={review.id} textAlign={'center'} sx={{ ml: -2, borderRadius: 1, boxShadow: 1,padding: 2 }}>
                        <Box sx={{
                          marginBottom: 1,
                          marginRight: { sm: '0%', md: '0%', xs: '9%' },
                          width: '100%',
                        }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}></Box>
                          {review.is_verified ? (
                            <Box>
                              <Box  sx={{ display: 'flex',flexDirection:'column',justifyContent:'space-between' }}>
                                <Box  sx={{ display: 'flex' }}>
                                <VerifiedIcon sx={{ color: "green", fontSize: '16px' }} />
                                <Typography variant='h6' sx={{ color: 'green', fontWeight: 'bold', fontSize: '10px',mb:'3%' }}>Verified</Typography>
                                </Box>
                              {!review?.exit_verified ? (
                                <Box sx={{ display: 'flex', flexDirection: 'row', mb: '2%' }}>
                                  <Typography sx={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>Exit not Verified</Typography>
                                </Box>
                              ) : ""}
                              </Box>
                            </Box>
                          ) : (
                            <Box>
                              <Box sx={{ display: 'flex' }}>
                                <VerifiedIcon sx={{ color: "red", fontSize: '16px' }} />
                                <Typography sx={{ color: 'red', fontWeight: 'bold', fontSize: '10px', marginRight: '-5%' }}>Not Verified</Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
                        <Avatar src={review?.profile_photo} sx={{ width: 40, height: 40, marginRight: 1 }} />
                        {businessId === review.organization && (
                          <EditIcon
                            onClick={() => handleEdit(review.id)}
                            sx={{
                              cursor: 'pointer',
                              color: '#82B2C4',
                              fontSize: '20px',
                              marginLeft: 1,
                              '&:hover': {
                                color: '#6B99A9'
                              }
                            }}
                          />
                        )}
                      </Box>
                        <Box>
                          <Typography variant="body1" color='#000'>{review?.business_name}</Typography>
                          <Typography variant="body2" color="#000" sx={{ fontfamily: 'Inter' }}>
                            {getDesignationName(review?.designation, designationOptions)}
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 'bold', fontfamily: 'Inter' }}>
                            {review?.title}
                          </Typography>
                          <Typography variant="caption" fontWeight='bold' color='#000'>
                            {`${new Date(review?.start_date).toLocaleString('default', { month: 'short' })}-${new Date(review?.start_date).getFullYear()} to ${new Date(review?.end_date).toLocaleString('default', { month: 'short' })}-${new Date(review?.end_date).getFullYear()}`}
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 'bold', fontfamily: 'Inter' }}>
                          {review.is_verified ? (
                            <>
                              {<Rating value={parseInt(review?.rating)} readOnly/>}
                              {review?.review ?(
                                <Typography variant="body2" color="#000" sx={{display:'flex', fontfamily: 'Inter',justifyContent:'center',gap:1,wordBreak:'break-word',lineHeight:1.5}}>
                                <b style={{color:"#65A3DD"}}>Review : </b> {review?.review}
                              </Typography>
                              ):""}
                          <Typography color='#65A3DD' >
                            {review?.company_rating_reply ? (
                              <Typography variant="body2" color='#000' display='flex' gap={1} justifyContent='center' sx={{wordBreak:'break-word',lineHeight:1.5}}>
                               <b style={{color:"#65A3DD"}}>Response : </b>  {review?.company_rating_reply}
                              </Typography>
                            ) : ""}
                          </Typography>
                            </>
                          ) : ""}
                          </Typography>
                         
 
                        </Box>
                      </Grid>
                  ))}
              </Grid>

              {/* Other Organizations Section */}
              {employeeDetails?.previous_experiences.some(review => businessId !== review.organization) && (
                <>
                    <Typography variant="h5" sx={{
                      fontWeight: 'bold',
                      marginTop: 4,
                      marginBottom: 3,
                      marginLeft: "5%",
                      borderBottom: '2px solid #000',
                      display: 'inline-block'
                    }}>
                      Other Organizations 
                    </Typography>

                  <Grid container spacing={2} gap={4} mt={2} width={{ xs: '100%', sm: '100%', md: '100%' }}
                    sx={{ justifyContent: 'center', px: 5 }}>
                    {employeeDetails?.previous_experiences
                      .filter(review => businessId !== review.organization)
                      .map((review, index) => (
                        <Grid item xs={22} sm={12} md={6} key={review.id} textAlign={'center'} sx={{ ml: -2, borderRadius: 1, boxShadow: 1,padding: 2 }}>
                        <Box sx={{
                          marginBottom: 1,
                          marginRight: { sm: '0%', md: '0%', xs: '9%' },
                          width: '100%',
                        }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}></Box>
                          {review.is_verified ? (
                            <Box>
                              <Box  sx={{ display: 'flex',flexDirection:'column',justifyContent:'space-between' }}>
                                <Box  sx={{ display: 'flex' }}>
                                <VerifiedIcon sx={{ color: "green", fontSize: '16px' }} />
                                <Typography variant='h6' sx={{ color: 'green', fontWeight: 'bold', fontSize: '10px',mb:'3%' }}>Verified</Typography>
                                </Box>
                                  {!review?.exit_verified ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: '2%' }}>
                                      <Typography sx={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>Exit not Verified</Typography>
                                    </Box>
                                  ) : ""}
                              </Box>
                            </Box>
                          ) : (
                            <Box>
                              <Box sx={{ display: 'flex' }}>
                                <VerifiedIcon sx={{ color: "red", fontSize: '16px' }} />
                                <Typography sx={{ color: 'red', fontWeight: 'bold', fontSize: '10px', marginRight: '-5%' }}>Not Verified</Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
                        <Avatar src={review?.logo} sx={{ width: 40, height: 40, marginRight: 1 }} />
                        {businessId === review.organization && (
                          <EditIcon
                            onClick={() => handleEdit(review.id)}
                            sx={{
                              cursor: 'pointer',
                              color: '#82B2C4',
                              fontSize: '20px',
                              marginLeft: 1,
                              '&:hover': {
                                color: '#6B99A9'
                              }
                            }}
                          />
                        )}
                      </Box>
                        <Box>
                          <Typography variant="body1" color='#000'>{review?.business_name}</Typography>
                          <Typography variant="body2" color="#000" sx={{ fontfamily: 'Inter' }}>
                            {getDesignationName(review?.designation, designationOptions)}
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 'bold', fontfamily: 'Inter' }}>
                            {review?.title}
                          </Typography>
                          <Typography variant="caption" fontWeight='bold' color='#000'>
                            {`${new Date(review?.start_date).toLocaleString('default', { month: 'short' })}-${new Date(review?.start_date).getFullYear()} to ${new Date(review?.end_date).toLocaleString('default', { month: 'short' })}-${new Date(review?.end_date).getFullYear()}`}
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 'bold', fontfamily: 'Inter' }}>
                          {review.is_verified ? (
                            <>
                              {<Rating value={parseInt(review?.rating)} readOnly/>}
                              {review?.review ?(
                                <Typography variant="body2" color="#000" sx={{display:'flex', fontfamily: 'Inter',justifyContent:'center',gap:1,wordBreak:'break-word',lineHeight:1.5}}>
                                <b style={{color:"#65A3DD"}}>Review : </b> {review?.review}
                              </Typography>
                              ):""}
                          <Typography color='#65A3DD' >
                            {review?.company_rating_reply ? (
                              <Typography variant="body2" color='#000' display='flex' gap={1} justifyContent='center' sx={{wordBreak:'break-word',lineHeight:1.5}}>
                               <b style={{color:"#65A3DD"}}>Response : </b>  {review?.company_rating_reply}
                              </Typography>
                            ) : ""}
                          </Typography>
                            </>
                          ) : ""}
                          </Typography>
                         
 
                        </Box>
                      </Grid>
                      ))}
                  </Grid>
                </>
              )}
            </Paper>
          )}
        </Box>
      )}
    </>
  );
}

export default EmployeeDetails;

EmployeeViewCard.propTypes = {
  employeeDetails: PropTypes.shape({
    current_experience: PropTypes.object,
    previous_experiences: PropTypes.array,
  }).isRequired,
  id: PropTypes.string.isRequired
};