import React from 'react';
import why1 from '../../assets/why1.png';
import why2 from '../../assets/why2.png';
import why3 from '../../assets/why3.png';
import why4 from '../../assets/why4.png';

const WhyChooseUs = () => {
  const handleFirstHover = (event) => {
    event.currentTarget.querySelector('.why-choose-us-left').classList.add('expanded');
  };
  const handleMouseLeave = (event) => {
    event.currentTarget.querySelector('.why-choose-us-left').classList.remove('expanded');
  };

  const imageStyle = {
    width: '24px',
    height: '24px',
    marginRight: '3%',
    verticalAlign: 'middle',
    objectFit: 'contain',
  };

  return (
    <section 
      className="why-choose-us-container" 
      onMouseEnter={handleFirstHover} 
      onMouseLeave={handleMouseLeave}
      aria-label="Why Choose Us"
    >
      <div className="why-choose-us-left">
        <h1><b>Why Choose Us?</b></h1>
      </div>
      <div className="why-choose-us-right">
        <div className="point">
          <img
          srcSet={`${why2} 600w, ${why2} 1200w`}
          sizes="(max-width: 600px) 100vw, 50vw"
          src={why2} alt="Why 2" style={imageStyle} /> Decades of Business Expertise
        </div>
        <div className="divider"></div>
        <div className="point">
          <img
           srcSet={`${why3} 600w, ${why3} 1200w`}
           sizes="(max-width: 600px) 100vw, 50vw" 
           src={why3} alt="Why 3" style={imageStyle} /> Maximum Gains, Minimal Fee
        </div>
        <div className="divider"></div>
        <div className="point">
          <img 
          srcSet={`${why1} 600w, ${why1} 1200w`}
          sizes="(max-width: 600px) 100vw, 50vw"
          src={why1} alt="Why 1" style={imageStyle} /> Secure and Confidential
        </div>
        <div className="divider"></div>
        <div className="point">
          <img
          srcSet={`${why4} 600w, ${why4} 1200w`}
          sizes="(max-width: 600px) 100vw, 50vw" 
          src={why4} alt="Why 4" style={imageStyle} /> Dedicated Support Team
        </div>
        <div className="divider"></div>
      </div>
    </section>
  );
};export default WhyChooseUs;
