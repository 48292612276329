import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "../../components/sidebar";
import { getMyPartnerAction, sendRatingsmsAction ,clearSendRatingsmsAction} from "../../store/actions";
import Loader from "../../components/loader";
import MoreDetails from "../moredetails/ratemycustomer";
import "../moredetails/moredetails.scss";
import "../side/Side.css";
import "./Dashboard.scss";
import "./modal.scss";
import PlanExpiry from "../login/PlanExpiry";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Pagination from '@mui/material/Pagination';
import MenuItem from "@mui/material/MenuItem";
import { CiFilter } from "react-icons/ci";
import Tooltip from '@mui/material/Tooltip';
import { toast } from "react-toastify";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [partnersData, setPartnersData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showPlanExpiryModal, setShowPlanExpiryModal] = useState(true);
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(20);

  const [locationFilter, setLocationFilter] = useState('all');
  const [myRatingFilter, setMyRatingFilter] = useState('all');
  const [avgRatingFilter, setAvgRatingFilter] = useState('all');
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [showfilters, setShowfilters] = useState(false);

  const userDetailString = localStorage.getItem("UserDetail");
  const userDetail = userDetailString ? JSON.parse(userDetailString) : {};

  const { loading: loadingMyPartners, successData: myPartnersData } =
    useSelector((state) => state.getMyPartnerData) || {};


  const { successData: sendRatingsmsData , error:sendRatingsmserror, errorInfo: sendRatingsmserrorInfo } = useSelector((state) => state.sendRatingsmsReducer) || {};

  useEffect(() => {
    const handlePopState = () => navigate("/dashboard");
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    dispatch(getMyPartnerAction());
  }, [dispatch]);

  useEffect(() => {
    if (myPartnersData && myPartnersData.length > 0) {
      setPartnersData(myPartnersData);
    }
  }, [myPartnersData]);

  const onSearchChange = useCallback(
    (e) => {
      const value = e.target.value.toLowerCase();
      setSearchValue(value);
      if (value) {
        const filteredData = myPartnersData.filter((item) =>
          item.partner_business_name.toLowerCase().includes(value)
        );
        setPartnersData(filteredData);
      } else {
        setPartnersData(myPartnersData);
      }
    },
    [myPartnersData]
  );

  const onClickEditDetails = (rowIndex) => {
    const rowData = partnersData[rowIndex];
    navigate("/editviewrating", {
      state: { partnerFullData: rowData, isEdit: true },
    });
  };

  const onClickViewDetails = (rowIndex) => {
    const selectedAssocPartner = partnersData[rowIndex];
    navigate("/partnerdetails", {
      state: { selectedAssocPartner },
      replace: false,
    });
  };


  const onUpdateRatingSuccess = () => {
    setShowModal(false);
    dispatch(getMyPartnerAction());
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (myPartnersData && Array.isArray(myPartnersData)) {
      const locations = [...new Set(myPartnersData.map(partner => partner.district))];
      setUniqueLocations(locations);
    }
  }, [myPartnersData]);

  const handleSendReminder = (partner) => {
    console.log("partner", partner);  
    dispatch(sendRatingsmsAction({endPoint:`send-rating-sms/?partner_id=${partner}`}));
  };

  useEffect(() => {
    if(sendRatingsmsData) {
      toast.success("Rating reminder sms sent successfully");
    }
   if(sendRatingsmserror) {
    toast.error(sendRatingsmserrorInfo);
   }  

   return () =>{
     dispatch(clearSendRatingsmsAction())
   }
  } ,[sendRatingsmsData, sendRatingsmserror, sendRatingsmserrorInfo]);


  const getFilteredPartners = () => {
    return partnersData.filter(partner => {
      const locationMatch = locationFilter === 'all' || partner.district === locationFilter;
      const myRatingMatch = myRatingFilter === 'all' ||
        (myRatingFilter === 'high' && partner.my_rating >= 7) ||
        (myRatingFilter === 'medium' && partner.my_rating >= 4 && partner.my_rating < 7) ||
        (myRatingFilter === 'low' && partner.my_rating < 4);
      const avgRatingMatch = avgRatingFilter === 'all' ||
        (avgRatingFilter === 'high' && parseFloat(partner.avg_rating) >= 7) ||
        (avgRatingFilter === 'medium' && parseFloat(partner.avg_rating) >= 4 && parseFloat(partner.avg_rating) < 7) ||
        (avgRatingFilter === 'low' && parseFloat(partner.avg_rating) < 4);

      return locationMatch && myRatingMatch && avgRatingMatch;
    });
  };
  const renderPartnersContent = () => {
    if (loadingMyPartners) {
      return <Loader className="loader" />;
    }

    const filteredPartners = getFilteredPartners();
    const indexOfLastItem = page * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredPartners.slice(indexOfFirstItem, indexOfLastItem);

    if (filteredPartners.length > 0) {
      return (
        <>
          {currentItems.map((partner, index) => (
            <Card
              key={partner.id || partner.partner_business_name}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 1,
                margin: 1,
                gap: 2,
                boxShadow: 3,
                borderRadius: "15px",
                flexDirection: { xs: "column", sm: "row" },
                maxWidth: { xs: "100%", sm: "100%" },
                marginLeft: { xs: -1, sm: 0 },
              }}
            >
              <CardContent
                sx={{
                  flex: "1 0 auto",
                  display: "flex",
                  flexDirection: { xs: "row", sm: "row" },
                  alignItems: "center",
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                  justifyContent: "space-between",
                  textAlign: "center",
                  width: "100%",
                  maxWidth: { xs: "100%", sm: "100%" },
                }}
              >
                <Typography
                  variant="h6"
                  color="text.primary"
                  sx={{
                    flex: { xs: "100%", sm: 1 },
                    fontSize: "18px",
                    fontWeight: 500,
                    minWidth: { xs: "100%", sm: "30%" },
                    background:
                      "linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontFamily: "Montserrat",
                  }}
                >
                  {partner.partner_business_name}
                </Typography>

                <Typography
                  className="rating-text"
                  sx={{
                    flex: { xs: "100%", sm: 1 },
                    margin: { xs: "8px 0", sm: 0 },
                    fontSize: "14px",
                  }}
                >
                  {partner.industry},<br />
                  {partner.partner_type}
                </Typography>

                <Typography
                  className="rating-text"
                  component="div"
                  sx={{
                    flex: { xs: "45%", sm: 1 },
                    margin: { xs: "8px 0", sm: 0 },
                    "& .gradient-text": {
                      background:
                        "linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontFamily: "inter",
                      display: "block",
                      marginBottom: 1,
                    },
                  }}
                >
                  <Box component="span" className="gradient-text">
                    Location:
                  </Box>
                  {partner.city}
                  <br />
                  {partner.district}
                </Typography>

                <Typography
                  className="rating-text"
                  component="div"
                  sx={{
                    flex: { xs: "45%", sm: 1 },
                    margin: { xs: "8px 0", sm: 0 },
                    "& .gradient-label": {
                      background:
                        "linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontFamily: "inter",
                      display: "block",
                      mb: 1,
                    },
                  }}
                >
                  <Box component="span" className="gradient-label">
                    MyRating:
                  </Box>
                  {partner.my_rating}
                </Typography>

                <Typography
                  className="rating-text"
                  component="div"
                  sx={{
                    flex: { xs: "45%", sm: 1 },
                    margin: { xs: "8px 0", sm: 0 },
                    "& .gradient-text": {
                      background:
                        "linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontFamily: "inter",
                      display: "block",
                      mb: 1,
                    },
                  }}
                >
                  <Box component="span" className="gradient-text">
                    TotalRating:
                  </Box>
                  {partner.avg_rating
                    ? parseFloat(partner.avg_rating).toFixed(2)
                    : "N/A"}
                </Typography>

                <Typography
                  className="rating-text"
                  component="div"
                  sx={{
                    flex: { xs: "45%", sm: 1 },
                    margin: { xs: "8px 0", sm: 0 },
                    "& .gradient-text": {
                      background:
                        "linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontFamily: "inter",
                      display: "block",
                      mb: 1,
                    },
                  }}
                >
                  <Box component="span" className="gradient-text">
                    LatestUpdate:
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        color: getPercentageColor(
                          partner.raise_or_fall_percent
                        ),
                      }}
                    >
                      {partner.raise_or_fall_percent
                        ? parseFloat(partner.raise_or_fall_percent).toFixed(2)
                        : "0"}
                    </Box>
                    {partner.raise_or_fall_percent > 0 && (
                      <ArrowUpwardIcon sx={{ color: "green", ml: "5px" }} />
                    )}
                    {partner.raise_or_fall_percent < 0 && (
                      <ArrowDownwardIcon sx={{ color: "red", ml: "5px" }} />
                    )}
                  </Box>
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: { xs: "100%", sm: "auto" },
                    justifyContent: { xs: "center", sm: "flex-start" },
                    marginTop: { xs: 2, sm: 0 },
                    gap: "10px",
                  }}
                >
                 <Box>
                 <Button
                    variant="contained"
                    onClick={() => onClickViewDetails(index)}
                    sx={{
                      margin: "5px",
                      background:
                        "linear-gradient(90deg, rgb(71, 98, 123) 0%, rgb(74, 135, 190) 100%)",
                      "&:hover": {
                        background:
                          "linear-gradient(90deg, rgb(74, 135, 190) 0%, rgb(71, 98, 123) 100%)",
                      },
                    }}
                  >
                    View
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => onClickEditDetails(index)}
                    sx={{
                      background:
                        "linear-gradient(90deg, rgb(71, 98, 123) 0%, rgb(74, 135, 190) 100%)",
                      "&:hover": {
                        background:
                          "linear-gradient(90deg, rgb(74, 135, 190) 0%, rgb(71, 98, 123) 100%)",
                      },
                    }}
                  >
                    Edit
                  </Button>
                 </Box>
                  <Box>
                {/* {partner.my_rating < 5 && userDetail.premium_type > 2 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        handleSendReminder(partner.business_partner_id)
                      }
                      sx={{
                        background:
                          "linear-gradient(90deg, rgb(71, 98, 123) 0%, rgb(74, 135, 190) 100%)",
                        "&:hover": {
                          background:
                            "linear-gradient(90deg, rgb(74, 135, 190) 0%, rgb(71, 98, 123) 100%)",
                        },
                      }}
                    >
                      Send Reminder
                    </Button>
                  )} */}
                </Box>
              </Box>
                
              </CardContent>
            </Card>
          ))}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 3 }}>
            <Pagination
              count={Math.ceil(filteredPartners.length / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
              color="primary"
              size="large"
            />
          </Box>
        </>
      );
    }

    return (
      <Typography variant="h5" align="center" className="no-partners-message">
        No partners found matching the selected filters
      </Typography>
    );
  };
  useEffect(() => {
    const planData = userDetail.is_plan_expired;
    const parsedPlanData = planData || null;
    setShowPlanExpiryModal(parsedPlanData);
  }, []);
  const handleFilterClick = () => {
    setShowfilters(!showfilters);
  }
  
  const getPercentageColor = (percentage) => {
    if (percentage > 0) return 'green';
    if (percentage < 0) return 'red';
    return 'black';
  };
  
  return (
    <>
      <Box sx={{ display: "flex", minHeight: "90vh" }}>
        <SideBar />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }} mt={{ xs: 14, sm: 8 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontSize: { xs: '1.75rem', sm: '2.5rem', color: '#2d4356', } }}>Partners Data</Typography>
            <Box>
              <Typography sx={{ fontSize: '18px', mt: { xs: '0%', sm: '5%' }, backgroundColor: '#2b4257', color: '#fff', borderRadius: '5px', padding: '5px 10px' }}>
                Total Partners: {partnersData.length}
              </Typography>
              <Box sx={{ display: { xs: 'none', sm: 'block' }, mt: { xs: '0%', sm: '5%' } }}>
                <Link to="/bulkRating" style={{ fontSize: '18px' }} >Bulk Rating Upload</Link>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row', sm: 'row' }, justifyContent: 'space-between', m: '2%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: '1px solid #0e3c58',
                  borderRadius: '5px',
                  padding: '5px 10px',
                  width: '100%',
                  maxWidth: '300px'
                }}
              >
                <TextField
                  fullWidth
                  id="search"
                  value={searchValue}
                  onChange={onSearchChange}
                  placeholder="Search business name"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true
                  }}
                />
                <SearchIcon
                  sx={{
                    color: "#0e3c58",
                    ml: 1,
                    cursor: 'pointer'
                  }}
                />
              </Box>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Tooltip title="Filter Partners" arrow>
                  <span style={{ display: 'inline-flex' }}>
                    <CiFilter size={30} style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={handleFilterClick} />
                  </span>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ flexWrap: 'wrap', mt: { md: '1%', xs: '5%', sm: '1%' }, display: { xs: showfilters ? 'flex' : 'none', sm: 'block' }, flexDirection: { xs: 'column', sm: 'column',md:'row' }, }}>
              <TextField
                select
                label=" Filter by District"
                value={locationFilter}
                onChange={(e) => setLocationFilter(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ mr:{xs:4,sm:1,md:1}, mb: { xs: '5%', sm: '0', md: '0%' }, ml: { xs: '10%', sm: '0%', md: '0%' } }}
              >
                <MenuItem value="all">All Locations</MenuItem>
                {uniqueLocations.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                label="My Rating"
                value={myRatingFilter}
                onChange={(e) => setMyRatingFilter(e.target.value)}
                variant="outlined"
                size="small"
                sx={{  mr:{xs:4,sm:1,md:1}, mb: { xs: '5%', sm: '', md: '0%' }, ml: { xs: '10%', sm: '0%', md: '0%' } }}
              >
                <MenuItem value="all">All Ratings</MenuItem>
                <MenuItem value="high">High (7-10)</MenuItem>
                <MenuItem value="medium">Medium (4-6)</MenuItem>
                <MenuItem value="low">Low (0-3)</MenuItem>
              </TextField>

              <TextField
                select
                label="Total Rating"
                value={avgRatingFilter}
                onChange={(e) => setAvgRatingFilter(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ mr:{xs:4,sm:1,md:1},ml: { xs: '10%', sm: '0%', md: '0%' } }}
              >
                <MenuItem value="all">All Ratings</MenuItem>
                <MenuItem value="high">High (7-10)</MenuItem>
                <MenuItem value="medium">Medium (4-6)</MenuItem>
                <MenuItem value="low">Low (0-3)</MenuItem>
              </TextField>
            </Box>
          </Box>
          <Grid item xs={12} sm={12} md={12} lg={9} ml={2} mt={2}>
            {renderPartnersContent()}
          </Grid>
        </Box>
      </Box>
      <Modal
        style={{ marginTop: "60px", width: "100%" }}
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="ratemycustomer">
          <MoreDetails
            isEdit={true}
            onUpdateRatingSuccess={onUpdateRatingSuccess}
          />
          <FontAwesomeIcon
            className="close ratemycustomerclose top-right-icon"
            icon={faTimes}
            onClick={() => setShowModal(false)}
          />
        </Modal.Body>
      </Modal>
      <Modal
        style={{
          width: { xs: "100%", sm: "100%", md: "100%" },
          marginLeft: { xs: "0%", sm: "30%", md: "30%" },
          boxshadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        }}
        show={showPlanExpiryModal}
        onHide={() => setShowPlanExpiryModal(false)}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="ratemycustomer">
          <PlanExpiry />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Dashboard;
